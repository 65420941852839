import React, { useRef, useState, useEffect } from "react";
import "./FeatureJob.scss";
import noblePage from "../../../assets/img/nobelPage.svg";
import savejobIcon from "../../../assets/img/saveJob.svg";
import unsaveIcon from "../../../assets/img/SaveIcon.png";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import applied from "../../../assets/img/done_circular.svg";
import featured from "../../../assets/img/ad_circular.svg";
import confidential from "../../../assets/img/confidentialIcon.svg";
// import { GetTime } from "../../../core/components/Utility/Utility";
import { useNavigate } from "react-router-dom";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import axios from "axios";
import { SavedJob } from "../../../store/job/jobAction";

export default function FeatureJobCard() {
  const [jobData, setJobData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const navigate = useNavigate();
  const getJob = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/job/job-list?limit=10&&skip=0&&filter=featured&&wp_type=true`;
      const headers = {
        "Accept-Language": "en",
        Authorization: `Bearer ${token}`,
      };

      await axios
        .get(UPLOAD_URL, { headers })
        .then((res) => {
          console.log(res);
          // Construct the array of document objects including URLs
          setJobData(res?.data?.data);
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getJob();
  }, []);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === jobData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? jobData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const saveJob = (id) => {
    const job = jobData?.map((ele) => {
      if (ele?.id == id)
        return {
          ...ele,
          savedJob: {
            status: true,
          },
        };
      else return ele;
    });
    console.log(job);
    setJobData(job);
  };
  return (
    <>
      <div className="mb-4">
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={jobData}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {jobData?.map((item) => (
            <CarouselItem
              className="custom-tag"
              tag="div"
              key={item.key}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
            >
              <div
                className={"side-job-card-layout"}
                onClick={() => navigate(`/job/${item?.id}`)}
              >
                <div className="job-card bg-white p-3">
                  <div className="d-flex justify-content-between flex-wrap row-gap-2 mb-3 mb-md-0">
                    <div className="d-flex" style={{ width: "70%" }}>
                      {item?.company_visible ? (
                        <img
                          src={
                            item?.company?.icon
                              ? item?.company?.icon
                              : pagePlaceholder
                          }
                          alt="company_icon"
                          className="img-icon"
                        />
                      ) : (
                        <img
                          src={confidential}
                          alt="company_icon"
                          className="img-icon"
                        />
                      )}
                      <div className="mx-2">
                        <span
                          className="job-title card-control-text-oneline"
                          style={{ marginRight: "10px" }}
                        >
                          {item?.title}
                        </span>
                        <span className="company-title card-control-text-oneline">
                          {item?.company_visible
                            ? item?.company_name
                            : "Confidential"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <img
                        onClick={() => {
                          saveJob(item?.id);
                        }}
                        src={item?.savedJob?.status ? savejobIcon : unsaveIcon}
                        alt="save_icon"
                        className="save-icon mt-1"
                      />
                      {item?.appliedJob?.apply_status && (
                        <span
                          className="apllied"
                          style={{ color: "white", fontSize: "10px" }}
                        >
                          <div style={{ marginTop: "3px" }}>
                            <img
                              src={applied}
                              alt="applied"
                              style={{
                                height: "12px",
                                width: "12px",
                                marginRight: "2px",
                              }}
                            />
                            <span>Applied</span>
                          </div>
                        </span>
                      )}
                      {item?.featuredJob && (
                        <span className="featured">
                          <img src={featured} alt="applied" />
                          Featured
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between bottom-bar">
                    <div className="ml-5">
                      <span className="grey-color">
                        {item?.employment_type}
                        {item?.workspace_type
                          ? ` | ` + item?.workspace_type
                          : ""}
                      </span>
                    </div>
                    {/* <span className="blue-color">
                      {GetTime(item?.createdAt)}
                    </span> */}
                  </div>
                </div>
                <div className="bottom-card p-2">
                  <div className="d-flex justify-content-between">
                    <span className="blue-color address-text">
                      {item?.job_location}
                    </span>
                    {item?.apply_smart && (
                      <div className="d-flex" style={{ padding: "2px 0px" }}>
                        <img src={noblePage} alt="smart_apply" />
                        <span className="blue-color address-text ml-5">
                          Smart Apply
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CarouselItem>
          ))}
          {/* <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          /> */}
        </Carousel>
      </div>
    </>
  );
}
