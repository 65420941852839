import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Login from "../login";

const LoginModal = (props) => {
  const { modal, toggle, workerId, setLoginPopUp } = props;

  return (
    <div className="login-popup-modal">
      <Modal
        isOpen={modal}
        size="xl"
        centered
        className="profilePermission"
        toggle={toggle}
        backdrop={true}
      >
        {/* <ModalHeader toggle={toggle}>
          
        </ModalHeader> */}

        <ModalBody className="accessPermissionBox pt-5 pt-lg-0 d-flex flex-row flex-lg-nowrap flex-wrap   bg-transparent">
          <div className="LeftBox">
            <h3>Please Login to access Worker’s profiles</h3>
            <p>
              Please sign up separately or seamlessly continue with NobelPage. f
              you have a NobelPage account, we highly recommend to login with
              your NP account, as it streamlines profile management and unlocks
              additional features.
            </p>
          </div>
          <div className="abc">
            <Login workerId={workerId} setLoginPopUp={setLoginPopUp} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LoginModal;
