import { call } from "./apiCall";

class DashboardApi {
  getStaticData(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/get-static-pages`,
            params,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getWorkers(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/public/worker-list`,
            params,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  getExpRegion() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/public/experience-region",
            null,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getLocations(searchKey) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/location?search=${searchKey}`,
            null,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getReligionList() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/public/religion-list",
            null,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getEmploymentTypes() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/employment-type",
            null,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getProfessionList(query) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/public/profession",
            query,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getCountryList() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "api/v1/country", null, null, null);
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getJobs(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/job-list`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  getCityList() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "api/v1/country", null, null, null);
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getAdsList() {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/campaign-adbar`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}
export const dashboardApi = new DashboardApi();
