import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateJob,
  UpdateJobDetail,
  getApplicants,
  getJobDetail,
  getMyJobs,
} from "../../../store/job/jobAction";

import bottomIcon1 from "../../../assets/img/bottomIcon-1.png";
import bottomIcon2 from "../../../assets/img/bottomIcon-2.png";
import bottomIcon3 from "../../../assets/img/bottomIcon-3.png";
import bottomIcon4 from "../../../assets/img/bottomIcon-4.png";
import backArrow from "../../../assets/img/backArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import WorkCard from "../../WorkerCard/WorkCard";
import lockIcon from "../../../assets/img/lock.png";
import rightArrow from "../../../assets/img/Right.png";
import ApplicantCard from "../../ApplicantCard/ApplicantCard";
import { ToastContainer, toast } from "react-toastify";
import { profileApi } from "../../../api/profileApi";
import "../WorkerPoolProfile.scss";
import axios from "axios";
import WorkerInfoCard from "../../WorkerInfoCard/WorkerInfoCard";
const ViewApplicant = ({ selectJob, setProfileField }) => {
  const { myJob, applicant, jobDetail } = useSelector((state) => state.job);
  console.log(applicant);
  const [currencyCodes, setCurrencyCodes] = useState([]);
  const dispatch = useDispatch();
  const [job, setJob] = useState({});
  const navigate = useNavigate();
  // useEffect(() =>{
  //     const data = myJob.find((ele) => ele?.id == selectJob);
  //     setJob(data);
  // }, [])
  const currencySymbol = async () => {
    try {
      const res = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/USD`
      );
      if (res?.status == 200) {
        setCurrencyCodes(res?.data?.rates);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getApplicants({ job_id: jobDetail?.id, limit: 100 }));
  }, [jobDetail]);

  useEffect(() => {
    dispatch(getJobDetail(selectJob));
    currencySymbol();
  }, []);

  const deactivateJob = (id) => {
    dispatch(UpdateJobDetail(id, { expiry_date: new Date() }))
      .then((res) => {
        if (res.success) {
          toast.success("Job has been Deactivated");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const activateJob = (id) => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    let formattedDate = currentDate.toISOString();
    dispatch(UpdateJobDetail(id, { expiry_date: formattedDate }))
      .then((res) => {
        if (res.success) {
          console.log("kdhdjhfj");
          toast.success("Job has been activated");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const createSearch = (type_id, search) => {
    profileApi.createSearchHistory({
      platform_type: "wp",
      type: "job",
      type_id,
      search,
    });
  };

  const time = (date) => {
    const now = new Date();
    const createdAt = new Date(date);

    const timeDifference = now.getTime() - createdAt.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let timeAgo;

    if (daysDifference > 0) {
      timeAgo = daysDifference === 1 ? "1 day " : daysDifference + " days ";
    } else if (hoursDifference > 0) {
      timeAgo = hoursDifference === 1 ? "1 hr " : hoursDifference + " hrs ";
    } else if (minutesDifference > 0) {
      timeAgo =
        minutesDifference === 1 ? "1 min " : minutesDifference + " mins ";
    } else {
      timeAgo = "Just now";
    }
    return timeAgo;
  };

  return (
    <>
      <div className="profile-content-outer">
        <div className="workerpool-default-form-page view-applicant  px-2">
          <div className="back-btn" onClick={() => setProfileField("job")}>
            <img src={backArrow} alt="Icon" />
          </div>
          <div className="col-12">
             <div className="row">
                <div className="col-12" keys={jobDetail?.id}>
                  <div className="p-lg-4 p-3" style={{ background: "#F9F9F9" }}>
                    <div className="col-outer flex-column">
                      <div className="d-flex flex-wrap gap-2 justify-content-between mb-3">
                        <div className="left">
                          <h3 className="card-control-text-oneline d-block">
                            {jobDetail?.title}
                          </h3> 
                          <span className="detail card-control-text-oneline mt-1">
                            {jobDetail?.company_name}
                          </span> 
                        </div>
                        <div className="right">
                          {new Date(jobDetail?.expiry_date) > new Date() ? (
                            <>
                            <div className="d-flex flex-wrap gap-md-3 gap-2">
                              <button
                                className="apply-btn deactivate border-0 py-2 px-lg-4 px-md-3 px-2 text-nowrap w-auto"
                                onClick={() => deactivateJob(jobDetail?.id)}
                              >
                                Deactivated
                              </button>
                              <button
                                className="apply-btn border-0 py-2 px-lg-4 px-md-3 px-2 text-nowrap w-auto"
                                onClick={() => {
                                  createSearch(jobDetail?.id, jobDetail?.title);
                                  navigate(`/job/${jobDetail?.id}`);
                                }}
                              >
                                View Job
                              </button> 
                            </div>
                            </>
                          ) : (
                            <>
                            <div className="d-flex flex-wrap gap-md-3 gap-2">
                              <button className="del_btn border-0  py-2 px-lg-4 px-md-3 px-2 text-nowrap w-auto">
                                Delete
                              </button>
                              <button
                                className="apply-btn border-0 py-2 px-lg-4 px-md-3 px-2 text-nowrap w-auto"
                                onClick={() => activateJob(jobDetail?.id)}
                              >
                                Publish Again
                              </button>
                            </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-wrap gap-2 justify-content-between">
                          <div className="bottom-text d-flex flex-wrap gap-2">
                                {jobDetail?.job_location && (
                                  <span>
                                    <img src={bottomIcon1} alt="Icon" />{" "}
                                    {jobDetail?.job_location}
                                  </span>
                                )}
                                <span>
                                  <img src={bottomIcon2} alt="Icon" />{" "}
                                  {time(jobDetail?.createdAt)}
                                </span>
                                <span>
                                  <img src={bottomIcon3} alt="Icon" />{" "}
                                  {jobDetail?.min_experience} -{" "}
                                  {jobDetail?.max_experience}{" "}
                                  {jobDetail?.max_experience > 1 ? "years" : "year"}
                                </span>
                                <span>
                                  <img src={bottomIcon4} alt="Icon" />{" "}
                                  {Math.round(
                                    jobDetail?.min_salary *
                                      currencyCodes[jobDetail?.salary_type || "USD"]
                                  ) >= 1000
                                    ? Math.round(
                                        (jobDetail?.min_salary *
                                          currencyCodes[
                                            jobDetail?.salary_type || "USD"
                                          ]) /
                                          1000
                                      ) + "K"
                                    : Math.round(
                                        jobDetail?.min_salary *
                                          currencyCodes[jobDetail?.salary_type || "USD"]
                                      )}{" "}
                                  -{" "}
                                  {Math.round(
                                    jobDetail?.max_salary *
                                      currencyCodes[jobDetail?.salary_type || "USD"]
                                  ) >= 1000
                                    ? Math.round(
                                        (jobDetail?.max_salary *
                                          currencyCodes[
                                            jobDetail?.salary_type || "USD"
                                          ]) /
                                          1000
                                      ) + "K"
                                    : Math.round(
                                        jobDetail?.max_salary *
                                          currencyCodes[jobDetail?.salary_type || "USD"]
                                      )}{" "}
                                  {jobDetail?.salary_type || "USD"}
                                </span>
                                {jobDetail?.employment_type && (
                                  <span className="red-clr text-capitalize">
                                    {jobDetail?.employment_type?.split("-")?.length >= 2
                                      ? jobDetail?.employment_type?.split("-")?.join(" ")
                                      : jobDetail?.employment_type}
                                  </span>
                                )}
                          </div>
                          <p className="m-0 text-nowrap">
                            {Math.floor(
                              (new Date(jobDetail?.expiry_date) - new Date()) /
                                (1000 * 60 * 60 * 24)
                            )}{" "}
                            days remaining
                          </p>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
          </div>
         

          <h3 className="card-control-text-oneline d-block my-lg-4 my-3 pt-2">Applicants</h3>
          <div className="col-12">
            {applicant?.length > 0 ? (
              <div className="row g-3">
                {applicant?.map((item, index) => {
                  return (
                    <div
                      className="col-lg-6 col-12"
                      key={index}
                    >
                      {/* <ApplicantCard  item={item} allWorkerList={true} /> */}
                      <WorkerInfoCard workers={item} allWorkerList={true}/>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-3">
                <h4 className="mb-3">No Applicant Found</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewApplicant;
