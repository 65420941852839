import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../dashboard/WorkerDashboard.scss";
// import WokerPoolHeader from "../workerPoolHeader/Header";
// import WokerPoolFooter from "../workerpoolFooter/footer";
import userImg from "../../assets/img/user-img.png";
import placeholderUser from "../../assets/img/userDefaultImage.svg";
import rightArrow from "../../assets/img/Right.png";
import whiteCallIcon from "../../assets/img/whiteCallIcon.png";
import whiteWhatsappIcon from "../../assets/img/whiteWhatsappIcon.png";
import whiteMailIcon from "../../assets/img/whiteMailIcon.png";
import NobelJobIcon from "../../assets/img/noble-job.svg";
import HeaderLogo from "../Header/header";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplyJob,
  ApplyJobInDetail,
  getJobDetail,
} from "../../store/job/jobAction";
import { ToastContainer, toast } from "react-toastify";
import "./JobDetailPage.scss";
const JobDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jobDetail } = useSelector((state) => state.job);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log(user);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getJobDetail(id));
  }, []);

  const applyJob = (id) => {
    if (!isLoggedIn) navigate("/login");
    dispatch(ApplyJobInDetail(id))
      .then((res) => {
        if (res.success) {
          toast.success(res?.message);
        } else {
          toast.error(res?.response?.data?.message);
        }
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  };

  const handleLink = (link, type) => {
    const alr = window.confirm(`Do you want to continue with ${type}`);
    if (alr) window.open(link, "_blank");
  };

  const time = (date) => {
    const now = new Date();
    const createdAt = new Date(date);

    const timeDifference = now.getTime() - createdAt.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let timeAgo;

    if (daysDifference > 0) {
      timeAgo =
        daysDifference === 1 ? "1 day ago" : daysDifference + " days ago";
    } else if (hoursDifference > 0) {
      timeAgo =
        hoursDifference === 1 ? "1 hour ago" : hoursDifference + " hours ago";
    } else if (minutesDifference > 0) {
      timeAgo =
        minutesDifference === 1
          ? "1 minute ago"
          : minutesDifference + " minutes ago";
    } else {
      timeAgo = "Just Now";
    }
    return timeAgo;
  };
  return (
    <>
      {/* <WokerPoolHeader isJobDetailPage={true} /> */}
      <HeaderLogo />
      <section className="profileInfo JobDetailInfo">
        <div className="container-big job_detail_page">
          <div className="row gy-4">
            <div className="col-xl-9 col-lg-8 col-12 order-1 order-lg-0">
              <div className="userFullDetail">
                <div className="aboutUser">
                  <div className="userdetail job">
                    <div className="d-flex flex-wrap gap-2 justify-content-between mb-3">
                      <div className="left" style={{ width: "70%" }}>
                        <h3 className="name card-control-text-oneline">
                          {jobDetail?.title}
                        </h3>
                        <h5 className="workfield card-control-text-oneline mb-0 mt-3">
                          {jobDetail?.company_name}
                        </h5>
                      </div>
                      <div className="right">
                        {user?.worker?.type !== "provider" && (
                          <button
                            className={
                              jobDetail?.user_id !== user?.id
                                ? "apply-btn border-0 px-5"
                                : "d-none"
                            }
                            onClick={() => applyJob(jobDetail?.id)}
                            disabled={
                              jobDetail?.appliedJob &&
                              jobDetail?.appliedJob?.apply_status != "rejected"
                            }
                          >
                            {jobDetail?.appliedJob &&
                            jobDetail?.appliedJob?.apply_status != "rejected"
                              ? "Applied"
                              : "Apply Now"}
                          </button>
                        )}
                        <div className="jobPost mt-lg-4 mt-3 mx-md-3 mx-0">
                          <p>Posted {time(jobDetail?.createdAt)} </p>
                        </div>
                      </div>
                    </div>
                    <div className="jobDiscreption">
                      <h6>Job Description:</h6>
                      <p
                        style={{ wordBreak: "break-all", marginTop: "10px" }}
                        dangerouslySetInnerHTML={{
                          __html: jobDetail?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                  <ul className="userKeyList">
                    <li>
                      <div className="userProfileInfo w-100 table-responsive">
                        <table className="table table-borderless">
                          {jobDetail?.job_location && (
                            <tr>
                              <td>Job Location</td>
                              <td>:</td>
                              <td>
                                <strong>{jobDetail?.job_location}</strong>
                              </td>
                            </tr>
                          )}
                          {jobDetail?.profession && (
                            <tr>
                              <td>Category</td>
                              <td>:</td>
                              <td>
                                <strong>{jobDetail?.profession}</strong>
                              </td>
                            </tr>
                          )}
                          {jobDetail?.job_type && (
                            <tr>
                              <td>Job Type</td>
                              <td>:</td>
                              <td>
                                <strong>{jobDetail?.job_type} </strong>
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td>Gender Type</td>
                            <td>:</td>
                            <td>
                              <strong>
                                {jobDetail?.gender || "Male or Female"}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Experience</td>
                            <td>:</td>
                            <td>
                              <strong>
                                {jobDetail?.min_experience} -{" "}
                                {jobDetail?.max_experience}{" "}
                                {jobDetail?.max_experience <= 1
                                  ? "year"
                                  : "years"}
                              </strong>
                            </td>
                          </tr>
                          {jobDetail?.age && (
                            <tr>
                              <td>Age</td>
                              <td>:</td>
                              <td>
                                <strong>{jobDetail?.age}</strong>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>Salary</td>
                            <td>:</td>
                            <td>
                              <strong>
                                {jobDetail?.min_salary} -{" "}
                                {jobDetail?.max_salary}{" "}
                                {jobDetail?.salary_type || "USD"}{" "}
                              </strong>
                            </td>
                          </tr>
                          {jobDetail?.nationality?.length > 0 && (
                            <tr>
                              <td>Looking From</td>
                              <td>:</td>
                              <td>
                                <strong>
                                  {jobDetail?.nationality.join(", ")}
                                </strong>
                              </td>
                            </tr>
                          )}
                          {jobDetail?.religion && (
                            <tr>
                              <td>Religion</td>
                              <td>:</td>
                              <td>
                                <strong>{jobDetail?.religion}</strong>
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-12 order-0 order-lg-1">
              {jobDetail?.contact_detail && (
                <div className="card customCard mb-4 p-4">
                  <div className="companyInfo">
                    <h6>Job Poster</h6>
                    <div className="three-column-worker-section p-0 mt-4">
                      <div className="profile-box-outer flex-column pb-0 align-items-start">
                        <div className="profile-box px-2">
                          <img
                            src={
                              jobDetail?.user?.profile_img || placeholderUser
                            }
                            alt=""
                          />
                          <div className="profile-head">
                            <h3>
                              {jobDetail?.user?.first_name}{" "}
                              {jobDetail?.user?.last_name}
                            </h3>
                          </div>
                        </div>

                        <div className="icon-box">
                          {jobDetail?.user?.phone && (
                            <Link
                              to={`tel:${jobDetail?.user?.phone}`}
                              className="icon call-icon"
                            >
                              <img src={whiteCallIcon} alt="" />
                            </Link>
                          )}
                          {jobDetail?.user?.email && (
                            <div
                              className="icon"
                              onClick={() =>
                                handleLink(
                                  `mailto:${jobDetail?.user?.email}`,
                                  "mail"
                                )
                              }
                            >
                              <img src={whiteMailIcon} alt="" />
                            </div>
                          )}
                          {jobDetail?.user?.worker?.whatsapp && (
                            <div
                              className="icon whatsapp"
                              onClick={() =>
                                handleLink(
                                  `https://api.whatsapp.com/send?phone=${
                                    jobDetail?.user?.worker?.whatsapp.split(
                                      "-"
                                    )[1] || jobDetail?.user?.worker?.whatsapp
                                  }`,
                                  "whatsapp"
                                )
                              }
                            >
                              <img src={whiteWhatsappIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>

                      {jobDetail?.company?.id && (
                        <div className="companyLink d-flex align-items-center mt-4 gap-1 mx-3">
                          <img src={NobelJobIcon} alt="" />
                          <Link
                            style={{ color: "rgba(38, 72, 161, 1)" }}
                            to={`${process.env.REACT_APP_WEB_STAGE_URL}/directory/companydetail/${jobDetail?.company?.id}`}
                            target="_blank"
                          >
                            View Company Page
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="gradientCard">
                <h3>
                  Benefits of <span>NobelPage</span>
                </h3>
                <p>
                  Enjoy even more add-on packages, offering additional benefits
                  at discounted pricing. Your Professional Networking Platform,
                  where Skills Win over Bias!
                </p>
                <div
                  className="actionGroup"
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/signup`)
                  }
                >
                  <p className="btn btn-white">
                    Join Now <img src={rightArrow} alt="right-arrow" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <WokerPoolFooter /> */}
    </>
  );
};
export default JobDetailPage;
