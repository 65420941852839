import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { authApi } from "../../../api/authAPI";
import { seekerRegister } from "../../../store/auth/authActions";
import { FormGroup, Input } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import { profileApi } from "../../../api/profileApi";
import HeaderLogo from "../../Header/header";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SeekerBasicInfo = ({ pageNo, setPageNo, user, countryList }) => {
  let [data, setData] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    phone: user?.phone || "",
    whatsapp: user?.whatsapp || "",
    desire_country: user?.desire_country || "",
    nationality: user?.nationality || null,
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [errorLastName, setErrorLastName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");

  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkValidation("all")) {
      profileApi
        .postWorkerUserDetails(data, token, user?.id)
        .then((res) => {
          if (res.success) {
            toast.success(res?.message);
            navigate("/worker/profile-dashboard", { replace: true });
          } else {
            toast.error(res?.response?.data?.message);
          }
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  };

  const navigate = useNavigate();

  const checkValidation = (type) => {
    let stringName = /^[a-zA-Z\s._]+$/;
    let stringNameLast = /^[a-zA-Z\s._]+$/;

    let formIsValid = true;

    if (type === "all" || type === "firstName") {
      if (!data?.first_name.trim()) {
        setErrorFirstName("Enter your first name ");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else if (!stringName.test(data.first_name.trim())) {
        setErrorFirstName("First name should be characters only.");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else if (data.first_name.length > 50) {
        setErrorFirstName("Max 50 characters allowed.");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else {
        setErrorFirstName("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "lastName") {
      // if (!data.last_name) {
      //   setErrorLastName("Enter your last name.");
      //   formIsValid = false;
      //   return;
      // } else
      if (!data?.last_name.trim()) {
        setErrorLastName("Enter your last name ");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else if (!stringNameLast.test(data.last_name.trim())) {
        setErrorLastName("Last name should be characters only.");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else if (data.last_name.length > 50) {
        setErrorLastName("Max 50 characters allowed.");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else {
        setErrorLastName("");
        formIsValid = true;
      }
    }
    return formIsValid;
  };

  return (
    <>
      <HeaderLogo />
      <div className="job-preview-page workerpool-default-form-page">
        <div className="container d-flex justify-content-center">
          <div className="width-850">
            <div className="head">
              <span className="head-title">Basic Information</span>
            </div>
            <form>
              <div className="row">
                <div className="col-12 col-md-6">
                  <FormGroup className="mb-0">
                    <label htmlFor="password" className="form-label">First Name</label>
                    <Input
                      type="text"
                      placeholder="Enter first name"
                      name="first_name"
                      value={data?.first_name}
                      onChange={(e) => handleChange(e)}
                      onBlur={() => checkValidation("firstName")}
                    />
                  </FormGroup>
                  {errorFirstName && (
                    <p className="text-danger">{errorFirstName}</p>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <FormGroup className="mb-0">
                    <label htmlFor="password" className="form-label">Last Name</label>
                    <Input
                      type="text"
                      placeholder="Enter last name"
                      name="last_name"
                      value={data?.last_name}
                      onChange={(e) => handleChange(e)}
                      onBlur={() => checkValidation("lastName")}
                    />
                  </FormGroup>
                  {errorLastName && (
                    <p className="text-danger">{errorLastName}</p>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <FormGroup>
                    <label htmlFor="password" className="form-label">Phone</label>
                    <PhoneInput
                      inputStyle={{
                        border: "none",
                      }}
                      placeholder="Enter phone"
                      buttonClass="bg-white"
                      buttonStyle={{ border: "none" }}
                      country={"us"}
                      name="phone"
                      onChange={(e) => setData({ ...data, phone: e })}
                    />
                  </FormGroup>
                </div>
                <div className="col-12 col-md-6">
                  <FormGroup>
                    <label htmlFor="password" className="form-label">Whatsapp</label>
                    <PhoneInput
                      inputStyle={{
                        border: "none",
                      }}
                      placeholder="Enter phone"
                      buttonClass="bg-white"
                      buttonStyle={{ border: "none" }}
                      country={"us"}
                      name="whatsapp"
                      onChange={(e) => setData({ ...data, whatsapp: e })}
                    />
                  </FormGroup>
                </div>

                <div className="col-12 col-md-6">
                  <FormGroup>
                    <label className="form-label">Residence Country</label>
                    <select
                      className="form-select"
                      value={data?.desire_country}
                      onChange={(e) => {
                        setData({ ...data, desire_country: e.target.value });
                      }}
                    >
                      <option value="">Select</option>
                      {countryList?.map((item, index) => (
                        <option value={item?.name} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </div>

                <div className="col-12 col-md-6">
                  <FormGroup>
                    <label className="form-label">Nationality</label>
                    <select
                      className="form-select"
                      value={data?.nationality}
                      onChange={(e) => {
                        setData({ ...data, nationality: e.target.value });
                      }}
                    >
                      <option value="">Select</option>
                      {countryList?.map((item, index) => (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </div>
              </div>

              <div className="text-end d-flex gap-lg-3 gap-2 flex-wrap justify-content-md-between justify-content-center my-4">
                <button
                  type="submit"
                  className="submit_btn px-5 py-2 bg-transparent w-auto flex-grow-1 flex-md-grow-0"
                  onClick={(e) => navigate("/")}
                  style={{ color: "#19bcf4", borderRadius: 0 }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="submit_btn px-5 py-2 w-auto flex-grow-1 flex-md-grow-0"
                  onClick={(e) => handleSubmit(e)}
                  style={{ borderRadius: 0 }}
                >
                  Save & Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeekerBasicInfo;
