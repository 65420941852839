import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import smallWhiteLogo from "../../../assets/img/smallWhiteLogo.png";
import {
  getPublicToken,
  getUserAfterNPLogin,
  loadUser,
} from "../../../store/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import LoginWithNP from "./LoginWithNP";
import { setSession } from "../../../api/authAPI";
import { LOGIN_SUCCESS } from "../../../store/types";

const AskNobePage = (props) => {
  const { modal, toggle, type, isLogin } = props;
  const [continueNobelModal, setContinueNobelModal] = useState(false);
  const [genrateToken, setGenrateToken] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const getUser = () => {
    let token = window.localStorage.getItem("accessToken");
    return token;
  };

  useEffect(() => {
    dispatch(getPublicToken()).then((res) => {
      if (res?.success) {
        setGenrateToken(res.data);
        dispatch(getUserAfterNPLogin(res.data));
      }
    });
  }, []);

  const handleClick = () => {
    if (user?.worker) {
      // setSession(genrateToken);
      if (!getUser()) setSession(genrateToken);
      dispatch(loadUser()).then((res) => console.log("load user", res));
      navigate("/");
      dispatch({
        type: LOGIN_SUCCESS,
      });
    } else {
      const conf = window.confirm(
        "Do you want to sync your data from your nobel page account?"
      );
      navigate("/worker/basic-info", {
        state: { fromNP: true, allowNp: conf },
      });
    }
  };

  return (
    <div className="login-popup-modal">
      <Modal isOpen={modal} size="md" centered toggle={toggle} backdrop={true}>
        {/* <ModalHeader className="continue-as-header" toggle={toggle}>
          Log In with NobelPage
        </ModalHeader> */}

        <ModalBody className=" bg-transparent">
          <div className="mx-lg-5 mx-md-4 mx-1 my-2">
            <h5 className="fw-bold">Login with your NobelPage account</h5>
          </div>
          {user && genrateToken ? (
            <div
              className="noble-login-btn"
              style={{ margin: "30px" }}
              onClick={() => handleClick()}
            >
              <img src={smallWhiteLogo} alt="logo" />
              Continue as{" "}
              {user?.first_name?.slice(0, 12) +
                (user?.first_name?.length > 12 ? "..." : "")}
            </div>
          ) : (
            <div
              className="noble-login-btn card-control-text-oneline"
              style={{ margin: "30px" }}
              onClick={() => setContinueNobelModal(true)}
            >
              <img src={smallWhiteLogo} alt="logo" />
              Login on NobelPage
            </div>
          )}
        </ModalBody>
      </Modal>
      {continueNobelModal && (
        <LoginWithNP
          modal={continueNobelModal}
          toggle={() => setContinueNobelModal(!continueNobelModal)}
          type={type}
          isLogin={isLogin}
        />
      )}
    </div>
  );
};

export default AskNobePage;
