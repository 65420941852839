import { useDispatch, useSelector } from "react-redux";
import WorkerList from "./WorkerList";
import { useEffect, useState } from "react";
import {
  getAllCountries,
  getAllProfession,
  getAllReligion,
  getExperienceRegion,
  getWorkerList,
} from "../../store/dashboard/dashboardAction";
import { useLocation } from "react-router";
import { loadUser } from "../../store/auth/authActions";

function WorkerListContainer() {
  const dispatch = useDispatch();
  const {
    workerList,
    experienceRegion,
    religionList,
    catergoryList,
    professionList,
    countryList,
  } = useSelector((state) => state.dashboard);
  const { state } = useLocation();

  const [selectedCategory, setSelectedCategory] = useState(state?.category);
  const [professionError, setProfessionError] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState(
    state?.profession
  );
  const [age, setAge] = useState(state?.age);
  const [gender, setGender] = useState(state?.gender);
  const [experience, setExperience] = useState(state?.experience);
  const [nationality, setNationality] = useState(state?.nationality);
  const [roe, setRoe] = useState(state?.experience_region);
  const [employmentType, setEmploymentType] = useState(state?.work_type);
  const [createdBy, setCreatedBy] = useState(state?.createdBy);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    if (!state?.searchFilter) {
      dispatch(getWorkerList({ limit, skip }));
    }
    getRegionOfExperience();
    getNationality();
    getReligion();
    getCategory();
    if (isLoggedIn) {
      dispatch(loadUser());
    }

    dispatch(
      getWorkerList({
        profession: selectedProfession ? selectedProfession : null,
        experience: experience ? experience : null,
        age: age ? age : null,
        gender: gender ? gender : null,
        nationality: nationality ? nationality : null,
        experience_region: roe ? roe : null,
        work_type: employmentType ? employmentType : null,
        created_by: createdBy ? createdBy : null,
        limit,
        skip,
      })
    );
  }, []);
  useEffect(() => {
    getProfession();
  }, [selectedCategory]);

  const searchWorkers = (e) => {
    setSkip(0);
    e.preventDefault();
    if (selectedCategory) {
      !selectedProfession
        ? setProfessionError("Please choose profession")
        : setProfessionError("");
    }
    dispatch(
      getWorkerList({
        profession: selectedProfession ? selectedProfession : null,
        experience: experience ? experience : null,
        age: age ? age : null,
        gender: gender ? gender : null,
        nationality: nationality ? nationality : null,
        experience_region: roe ? roe : null,
        work_type: employmentType ? employmentType : null,
        created_by: createdBy ? createdBy : null,
        limit,
        skip,
      })
    );
  };

  const getRegionOfExperience = () => {
    dispatch(getExperienceRegion());
  };

  const getNationality = () => {
    dispatch(getAllCountries());
  };
  const getReligion = () => {
    dispatch(getAllReligion());
  };
  const getCategory = () => {
    dispatch(getAllProfession());
  };
  const getProfession = () => {
    dispatch(
      getAllProfession({ category: selectedCategory ? selectedCategory : null })
    );
  };

  const searchAllWorkers = () => {
    dispatch(getWorkerList({ limit, skip }));
  };

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const currentHeight =
      document.documentElement.scrollTop + window.innerHeight;
    if (currentHeight + 1 >= scrollHeight) {
      setSkip(skip + 10);
    }
  };
  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    dispatch(
      getWorkerList({
        profession: selectedProfession ? selectedProfession : null,
        experience: experience ? experience : null,
        age: age ? age : null,
        gender: gender ? gender : null,
        nationality: nationality ? nationality : null,
        experience_region: roe ? roe : null,
        work_type: employmentType ? employmentType : null,
        created_by: createdBy ? createdBy : null,
        limit,
        skip,
      })
    );

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [skip]);

  return (
    <>
      <WorkerList
        type="worker"
        workerList={workerList}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        professionError={professionError}
        setSelectedProfession={setSelectedProfession}
        setAge={setAge}
        setGender={setGender}
        setExperience={setExperience}
        setNationality={setNationality}
        experienceRegion={experienceRegion}
        setEmploymentType={setEmploymentType}
        createdBy={createdBy}
        setCreatedBy={setCreatedBy}
        countryList={countryList}
        religionList={religionList}
        catergoryList={catergoryList}
        professionList={professionList}
        searchWorkers={searchWorkers}
        setRoe={setRoe}
        user={user}
        searchAllWorkers={searchAllWorkers}
        skip={skip}
        setSkip={setSkip}
        age={age}
        selectedProfession={selectedProfession}
        gender={gender}
        nationality={nationality}
        employmentType={employmentType}
        experience={experience}
      />
    </>
  );
}

export default WorkerListContainer;
