import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { authApi } from "../../../api/authAPI";
import HeaderLogo from "../../Header/header";
import { toast } from "react-toastify";

const VerifyAccount = ({ setPageNo, pageNo, user }) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const handleValidation = () => {
    let formvalid = true;
    if (!otp) {
      {
        formvalid = false;
        setError("otp is required");
      }
    } else if (!otp.match(/^[0-9]+$/)) {
      formvalid = false;
      setError("OTP only contains numric value");
    } else setError("");
    return formvalid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      authApi
        .verifyOtp({ email: user?.email, otp: otp })
        .then((res) => {
          if (res.success) setPageNo(2);
          else toast.error(res?.response?.data?.message);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  };
  return (
    <>
      <HeaderLogo />
      <div className="job-preview-page workerpool-default-form-page">
        <div className="container">
          <div className="width-850 m-auto">
            <div className="head">
              <span className="head-title">Verification</span>
            </div>
            <p>
              Please verify your email by entering the one-time OTP sent to your
              inbox.
            </p>
            <form className="mt-4">
              <div className="row my-lg-5 my-md-4 my-3">
                <div className="col-md-6 col-12">
                  <FormGroup>
                    <label className="form-label" htmlFor="password">OTP</label>
                    <Input
                      type="text"
                      maxLength="4"
                      placeholder="Enter otp"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      onBlur={() => handleValidation()}
                    />
                    {error && <label className="text-danger">{error}</label>}
                  </FormGroup>
                </div>
              </div>
              <div className="d-flex flex-md-nowrap flex-wrap justify-content-start align-items-center mt-3 gap-md-5 gap-3">
                <button
                  type="submit"
                  className="submit_btn px-5 py-2 bg-transparent w-auto  mt-0"
                  onClick={(e) => setPageNo(0)}
                  style={{ color: "#19bcf4", borderRadius: 0, height:"50px" }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="submit_btn px-5 py-2  w-auto mt-0"
                  onClick={(e) => handleSubmit(e)}
                  style={{ borderRadius: 0, height:"50px"}}
                >
                  Verify
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
