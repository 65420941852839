import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import whiteCallIcon from "../../assets/img/phoneIcon.svg";
import whiteWhatsappIcon from "../../assets/img/whatsappIcon.svg";
import whiteMailIcon from "../../assets/img/emailIcon.svg";
import placeholderUser from "../../assets/img/userDefaultImage.svg";
import { useSelector } from "react-redux";
import LoginModal from "../login/component/LoginModal";
import { profileApi } from "../../api/profileApi";
import "./WorkerInfoCard.scss";
const WorkerInfoCard = (props) => {
  const { workers, allWorkerList } = props;
  const [loginPopUp, setLoginPopUp] = useState(false);
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [phoneNum, setPhoneNum] = useState("");
  const [mailTo, setMailTo] = useState("");
  const [whatsAppTo, setWhatsAppTo] = useState("");
  const navigate = useNavigate();
  const handleNavigate = () => {
    // if (isLoggedIn) {
    profileApi.createSearchHistory({
      platform_type: "wp",
      type: "worker",
      type_id: workers?.user?.id,
      search: workers?.user?.first_name + " " + workers?.user?.last_name,
    });
    navigate(`/workers/profile/${workers?.id}`);
    // } else {
    //   setLoginPopUp(true);
    // }
  };

  const handleContactNavigate = () => {
    if (isLoggedIn) {
      navigate(`/workers/profile/${workers?.id}`);
    } else {
      setLoginPopUp(true);
    }
  };

  const handleLink = (link, type) => {
    const alr = window.confirm(`Do you want to continue with ${type}`);
    if (alr) window.open(link, "_blank");
  };

  useEffect(() => {
    if (workers?.user?.phone) {
      setPhoneNum(`tel:${workers?.user?.phone}`);
    }
    if (workers?.whatsapp) {
      let newText = workers?.whatsapp?.split("-")[1] || workers?.whatsapp;
      setWhatsAppTo(`https://api.whatsapp.com/send?phone=${newText}`);
    }
    if (workers?.email) {
      setMailTo(`mailto:${workers?.email}`);
    }
  }, [workers]);

  return (
    <div
      className={"col-inner" + (!allWorkerList ? " col-inner-dashoard" : "")}
    >
      <div className="profile-box-outer worker_info_card">
        <div className="profile-boxSmall">
          <img
            src={
              workers?.user?.profile_img
                ? workers?.user?.profile_img
                : placeholderUser
            }
            alt="Icon"
            className="img-fluid"
          />
          <div className="profile-head" onClick={() => handleNavigate()}>
            <h3 className="card-control-text-oneline">
              {workers?.user?.first_name + " " + workers?.user?.last_name}
            </h3>
            <span className="location card-control-text-oneline">
              {workers?.desire_country}
            </span>
          </div>
        </div>
        <div className="icon-box" style={{ cursor: "pointer" }}>
          {phoneNum && (
            <div>
              {isLoggedIn ? (
                // <div className="" onClick={() => handleLink(phoneNum)}>
                <Link to={phoneNum} target="_blank">
                  <div className="mail-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1439_2597)">
                        <path
                          d="M18.3952 13.1277C17.1707 13.1277 15.9684 12.9362 14.8291 12.5597C14.2708 12.3693 13.5845 12.544 13.2438 12.8939L10.995 14.5915C8.38703 13.1994 6.78057 11.5934 5.40745 9.00505L7.0551 6.81484C7.48318 6.38734 7.63672 5.76286 7.45276 5.17693C7.07464 4.03161 6.88255 2.8299 6.88255 1.6049C6.8826 0.719948 6.16266 0 5.27776 0H1.60484C0.719948 0 0 0.719948 0 1.60484C0 11.7481 8.25198 20 18.3952 20C19.2801 20 20.0001 19.2801 20.0001 18.3952V14.7325C20 13.8477 19.2801 13.1277 18.3952 13.1277Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1439_2597">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              ) : (
                // </div>

                <div className="mail-icon" onClick={() => setLoginPopUp(true)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1439_2597)">
                      <path
                        d="M18.3952 13.1277C17.1707 13.1277 15.9684 12.9362 14.8291 12.5597C14.2708 12.3693 13.5845 12.544 13.2438 12.8939L10.995 14.5915C8.38703 13.1994 6.78057 11.5934 5.40745 9.00505L7.0551 6.81484C7.48318 6.38734 7.63672 5.76286 7.45276 5.17693C7.07464 4.03161 6.88255 2.8299 6.88255 1.6049C6.8826 0.719948 6.16266 0 5.27776 0H1.60484C0.719948 0 0 0.719948 0 1.60484C0 11.7481 8.25198 20 18.3952 20C19.2801 20 20.0001 19.2801 20.0001 18.3952V14.7325C20 13.8477 19.2801 13.1277 18.3952 13.1277Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1439_2597">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              )}
            </div>
          )}
          {mailTo && (
            <div>
              {isLoggedIn ? (
                <div className="" onClick={() => handleLink(mailTo, "mail")}>
                  <div className="mail-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.9592 10.8301L11.2841 12.5108C10.6049 13.1924 9.40973 13.2071 8.71578 12.5108L7.04066 10.8301L1.02441 16.8659C1.24836 16.9694 1.49527 17.0312 1.75777 17.0312H18.2421C18.5046 17.0312 18.7515 16.9695 18.9754 16.8659L12.9592 10.8301Z"
                        fill="white"
                      />
                      <path
                        d="M18.2421 2.96875H1.7577C1.4952 2.96875 1.24828 3.03055 1.02441 3.1341L7.4532 9.58422C7.45363 9.58465 7.45414 9.58473 7.45457 9.58516C7.455 9.58559 7.45508 9.58617 7.45508 9.58617L9.54543 11.6834C9.76746 11.9055 10.2324 11.9055 10.4544 11.6834L12.5443 9.58652C12.5443 9.58652 12.5448 9.58559 12.5453 9.58516C12.5453 9.58516 12.5462 9.58465 12.5466 9.58422L18.9753 3.13406C18.7514 3.03047 18.5046 2.96875 18.2421 2.96875Z"
                        fill="white"
                      />
                      <path
                        d="M0.186953 3.95312C0.0710937 4.18742 0 4.4475 0 4.72609V15.273C0 15.5516 0.0710156 15.8116 0.186914 16.0459L6.21359 9.99973L0.186953 3.95312Z"
                        fill="white"
                      />
                      <path
                        d="M19.8127 3.95312L13.7861 9.99981L19.8127 16.0461C19.9286 15.8118 19.9997 15.5517 19.9997 15.273V4.72617C19.9997 4.4475 19.9286 4.18742 19.8127 3.95312Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="mail-icon" onClick={() => setLoginPopUp(true)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9592 10.8301L11.2841 12.5108C10.6049 13.1924 9.40973 13.2071 8.71578 12.5108L7.04066 10.8301L1.02441 16.8659C1.24836 16.9694 1.49527 17.0312 1.75777 17.0312H18.2421C18.5046 17.0312 18.7515 16.9695 18.9754 16.8659L12.9592 10.8301Z"
                      fill="white"
                    />
                    <path
                      d="M18.2421 2.96875H1.7577C1.4952 2.96875 1.24828 3.03055 1.02441 3.1341L7.4532 9.58422C7.45363 9.58465 7.45414 9.58473 7.45457 9.58516C7.455 9.58559 7.45508 9.58617 7.45508 9.58617L9.54543 11.6834C9.76746 11.9055 10.2324 11.9055 10.4544 11.6834L12.5443 9.58652C12.5443 9.58652 12.5448 9.58559 12.5453 9.58516C12.5453 9.58516 12.5462 9.58465 12.5466 9.58422L18.9753 3.13406C18.7514 3.03047 18.5046 2.96875 18.2421 2.96875Z"
                      fill="white"
                    />
                    <path
                      d="M0.186953 3.95312C0.0710937 4.18742 0 4.4475 0 4.72609V15.273C0 15.5516 0.0710156 15.8116 0.186914 16.0459L6.21359 9.99973L0.186953 3.95312Z"
                      fill="white"
                    />
                    <path
                      d="M19.8127 3.95312L13.7861 9.99981L19.8127 16.0461C19.9286 15.8118 19.9997 15.5517 19.9997 15.273V4.72617C19.9997 4.4475 19.9286 4.18742 19.8127 3.95312Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
          )}
          {whatsAppTo && (
            <div>
              {isLoggedIn ? (
                <div
                  className="whatsapp-icon"
                  onClick={() => handleLink(whatsAppTo, "whatsapp")}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1439_2618)">
                      <path
                        d="M10.0025 0H9.9975C4.48375 0 0 4.485 0 10C0 12.1875 0.705 14.215 1.90375 15.8612L0.6575 19.5763L4.50125 18.3475C6.0825 19.395 7.96875 20 10.0025 20C15.5162 20 20 15.5138 20 10C20 4.48625 15.5162 0 10.0025 0ZM15.8212 14.1213C15.58 14.8025 14.6225 15.3675 13.8587 15.5325C13.3363 15.6437 12.6537 15.7325 10.3562 14.78C7.4175 13.5625 5.525 10.5763 5.3775 10.3825C5.23625 10.1887 4.19 8.80125 4.19 7.36625C4.19 5.93125 4.91875 5.2325 5.2125 4.9325C5.45375 4.68625 5.8525 4.57375 6.235 4.57375C6.35875 4.57375 6.47 4.58 6.57 4.585C6.86375 4.5975 7.01125 4.615 7.205 5.07875C7.44625 5.66 8.03375 7.095 8.10375 7.2425C8.175 7.39 8.24625 7.59 8.14625 7.78375C8.0525 7.98375 7.97 8.0725 7.8225 8.2425C7.675 8.4125 7.535 8.5425 7.3875 8.725C7.2525 8.88375 7.1 9.05375 7.27 9.3475C7.44 9.635 8.0275 10.5937 8.8925 11.3637C10.0087 12.3575 10.9137 12.675 11.2375 12.81C11.4787 12.91 11.7662 12.8863 11.9425 12.6988C12.1663 12.4575 12.4425 12.0575 12.7238 11.6638C12.9237 11.3813 13.1763 11.3462 13.4412 11.4462C13.7113 11.54 15.14 12.2462 15.4338 12.3925C15.7275 12.54 15.9212 12.61 15.9925 12.7338C16.0625 12.8575 16.0625 13.4388 15.8212 14.1213Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1439_2618">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              ) : (
                <div
                  className="whatsapp-icon"
                  onClick={() => setLoginPopUp(true)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1439_2618)">
                      <path
                        d="M10.0025 0H9.9975C4.48375 0 0 4.485 0 10C0 12.1875 0.705 14.215 1.90375 15.8612L0.6575 19.5763L4.50125 18.3475C6.0825 19.395 7.96875 20 10.0025 20C15.5162 20 20 15.5138 20 10C20 4.48625 15.5162 0 10.0025 0ZM15.8212 14.1213C15.58 14.8025 14.6225 15.3675 13.8587 15.5325C13.3363 15.6437 12.6537 15.7325 10.3562 14.78C7.4175 13.5625 5.525 10.5763 5.3775 10.3825C5.23625 10.1887 4.19 8.80125 4.19 7.36625C4.19 5.93125 4.91875 5.2325 5.2125 4.9325C5.45375 4.68625 5.8525 4.57375 6.235 4.57375C6.35875 4.57375 6.47 4.58 6.57 4.585C6.86375 4.5975 7.01125 4.615 7.205 5.07875C7.44625 5.66 8.03375 7.095 8.10375 7.2425C8.175 7.39 8.24625 7.59 8.14625 7.78375C8.0525 7.98375 7.97 8.0725 7.8225 8.2425C7.675 8.4125 7.535 8.5425 7.3875 8.725C7.2525 8.88375 7.1 9.05375 7.27 9.3475C7.44 9.635 8.0275 10.5937 8.8925 11.3637C10.0087 12.3575 10.9137 12.675 11.2375 12.81C11.4787 12.91 11.7662 12.8863 11.9425 12.6988C12.1663 12.4575 12.4425 12.0575 12.7238 11.6638C12.9237 11.3813 13.1763 11.3462 13.4412 11.4462C13.7113 11.54 15.14 12.2462 15.4338 12.3925C15.7275 12.54 15.9212 12.61 15.9925 12.7338C16.0625 12.8575 16.0625 13.4388 15.8212 14.1213Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1439_2618">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {workers?.profession?.length > 0 && (
        <div className="skills-box">
          <h4>What i do</h4>
          <span className="skills">
            <ul>
              {/* {workers?.profession.map((item, index) => ( */}
              <li>{workers?.profession.join(" ● ")}</li>
              {/* ))} */}
            </ul>
          </span>
        </div>
      )}
      <div className="details">
        <p className="card-control-text-fourline">
          {workers?.user?.profile?.about}
        </p>
      </div>
      <div className="box-outer">
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="box">
            <h5>Experience</h5>
            {workers?.experience ? (
              <span className="box-info">
                {workers?.experience}{" "}
                {workers?.experience == 1 ? "year" : "years"}
              </span>
            ) : (
              <span className="box-info">Fresher</span>
            )}
          </div>
          <div className="box">
            <h5>Preference</h5>
            <span className="box-info text-capitalize">
              {workers?.work_type?.split("-")?.length
                ? workers?.work_type?.split("-").join(" ")
                : workers?.work_type}
            </span>
          </div>
          <div className="box">
            <h5>Profile</h5>
            <span className="box-info">
              {workers?.is_public ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div>
          <div className="mt-2">
            {workers?.created_by === "independent" ? (
              <span
                className="profileType public p-2 "
                style={{
                  background: "rgba(0, 168, 67, 0.10)",
                  borderRadius: "10px",
                  color: "rgba(0, 168, 67, 1)",
                }}
              >
                Independent
              </span>
            ) : (
              <span
                className="profileType private p-2"
                style={{
                  background: "rgba(255, 68, 43, 0.10)",
                  borderRadius: "10px",
                  color: "rgba(255, 68, 43, 1)",
                }}
              >
                Agency
              </span>
            )}
          </div>
        </div>
      </div>
      {loginPopUp && (
        <LoginModal
          modal={loginPopUp}
          toggle={() => setLoginPopUp(!loginPopUp)}
          workerId={workers?.id}
          setLoginPopUp={setLoginPopUp}
        />
      )}
    </div>
  );
};
export default WorkerInfoCard;
