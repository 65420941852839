import React, { useEffect } from "react";
import "./WorkerPoolLogin.scss";
import workerPoolLogo from "../../assets/img/workerLogo.svg";
import Login from "./login";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AppLoader from "../../core/Loaders/appLoader/appLoader";

const WorkerPoolLogin = () => {
  const navigate = useNavigate();
  const { isPageLoading } = useSelector((state) => state.loader);
  useEffect(() => {}, [isPageLoading]);
  return (
    <div className="worker-login-page overflow-x-hidden">
      {isPageLoading && <AppLoader />}
      <div className="container">
        <div className="worker-header">
          <div className="logo" onClick={() => navigate("/")}>
            <img src={workerPoolLogo} alt="logo" />
          </div>
          <div
            className="right-btn border border-white"
            onClick={() => navigate("/")}
          >
            Explore WorkerPool
          </div>
        </div>
        <Login />
      </div>
    </div>
  );
};

export default WorkerPoolLogin;
