import React, { useState } from "react";
import profile from "../../../assets/img/profile1.png";
import "../../../assets/styles/style.scss";
import { jobApi } from "../../../api/jobApi";
import JobPaymentModel from "../../JobPaymentModel/JobPaymentModel";
import JobPaymentSuccessModel from "../../JobPaymentModel/PaymentSuccessModel";

const JobPreview = ({ setExpData, data, setCon, user, currencyCodes }) => {
  const [isOpen, setIsModelOpen] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsModelOpen(true);
  };

  return (
    <>
      <div className="job-preview-page workerpool-default-form-page">
        <div className="container">
          <div className="width-850" style={{ margin: "auto" }}>
            <div className="head mb-xl-5 mb-lg-4 mb-3">
              <span className="head-title">Job Preview</span>
              <h2>{data?.title}</h2>
              <h4>{data?.company_name}</h4>
            </div>
            <h5>Job Description:</h5>
            <p className="mb-2" style={{ color: "#464646" }}>
              {data?.description}
            </p>
            <div className="userFullDetail">
              <ul className="userKeyList">
                <li>
                  <div className="userProfileInfo w-100 table-responsive">
                    <table className="table table-borderless">
                      {data?.job_location && (
                        <tr>
                          <td>Job Location</td>
                          <td>:</td>
                          <td>
                            <strong className="text-nowrap">
                              {data?.job_location}
                            </strong>
                          </td>
                        </tr>
                      )}
                      {data?.profession && (
                        <tr>
                          <td>Category</td>
                          <td>:</td>
                          <td>
                            <strong>{data?.profession}</strong>
                          </td>
                        </tr>
                      )}

                      {data?.job_type && (
                        <tr>
                          <td>Job Type</td>
                          <td>:</td>
                          <td>
                            <strong>{data?.employment_type} </strong>
                          </td>
                        </tr>
                      )}

                      {data?.gender && (
                        <tr>
                          <td>Gender Type</td>
                          <td>:</td>
                          <td>
                            <strong>{data?.gender}</strong>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>Gender Type</td>
                        <td>:</td>
                        <td>
                          <strong>
                            {data?.min_experience} - {data?.max_experience}{" "}
                            {data?.max_experience > 1 ? "years" : "year"}
                          </strong>
                        </td>
                      </tr>

                      {data?.age && (
                        <tr>
                          <td>Age</td>
                          <td>:</td>
                          <td>
                            <strong>{data?.age}</strong>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>Salary</td>
                        <td>:</td>
                        <td>
                          <strong>
                            {data?.min_salary} - {data?.max_salary}{" "}
                            {data?.salary_type}
                          </strong>
                        </td>
                      </tr>

                      {data?.nationality?.length > 0 && (
                        <tr>
                          <td>Looking From</td>
                          <td>:</td>
                          <td>
                            <strong>{data?.nationality?.join(", ")}</strong>
                          </td>
                        </tr>
                      )}

                      {data?.religion && (
                        <tr>
                          <td>Religion</td>
                          <td>:</td>
                          <td>
                            <strong>{data?.religion}</strong>
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                </li>
              </ul>
            </div>
            {data?.contact_detail && (
              <div className="d-flex flex-wrap justify-content-between align-items-center poster-box row-gap-3">
                <div className="d-flex align-items-center">
                  <img
                    src={user?.profile_img || profile}
                    alt="Image"
                    height={70}
                    width={70}
                  />
                  <h3 className="text-wrap text-break">
                    {user?.first_name}
                    {user?.last_name}
                  </h3>
                </div>
                <span className="right-txt">Job Poster</span>
              </div>
            )}

            <div className="text-center mt-5">
              <button
                className="btn btn-blue px-5 mb-4"
                onClick={(e) => handleSubmit(e)}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <JobPaymentModel
        isOpen={isOpen}
        setIsModelOpen={setIsModelOpen}
        setSuccessModel={setSuccessModel}
        data={data}
        setExpData={setExpData}
        currencyCodes={currencyCodes}
      />

      {successModel && (
        <JobPaymentSuccessModel
          setSuccessModel={setSuccessModel}
          successModel={successModel}
        />
      )}
    </>
  );
};

export default JobPreview;
