import React, { useState } from "react";
import Sidebar from "../Componenets/Sidebar";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "../WorkerPoolProfile.scss";
import "../../WorkerPoolFormPages/WorkerPoolDefaultForm.scss";
import { Link } from "react-router-dom";
import backArrow from "../../../assets/img/backArrow.svg";
import mastercardSmall from "../../../assets/img/mastercardSmall.png";
import visacrdSmall from "../../../assets/img/visacrdSmall.png";
import deleteIcon from "../../../assets/img/deleteBlack.png";
import { call } from "../../../api/apiCall";
import { profileApi } from "../../../api/profileApi";
import DeleteAccountModel from "./DeleteAcountModel";
import DeactiveAccountModel from "./DeactiveAccountModel";

const ProfileSetting = (props) => {
  const { setProfileField } = props;
const [openDeleteModel, setOpenDeleteModel] = useState(false)
const [openDeactivateModel, setOpenDeactivateModel] = useState(false)


  const deactivate = async () =>{
    const res = await profileApi.deactivateAccount();
    console.log(res);
    if(res?.success) window.location.href='/login';
  }


  const deleteAccount = async () =>{
    const res = await profileApi.deleteAccount();
    console.log(res);
    if(res?.success) window.location.href='/login';
  }
 
  return (
    // <div className="profile-dashboard-main">
    //     <div className='profile-container'>
    //         <div className="row">
    //             <div className="col-xxl-3 col-lg-2 col-1">
    //                 <Sidebar />
    //             </div>
    //             <div className="col-xxl-9 col-lg-10 col-12">
    <div className="profile-content-outer">
      <div className="workerpool-default-form-page mx-0 px-4 px-md-0">
        <div className="back-btn" onClick={() => setProfileField("profile")}>
          <img src={backArrow} alt="Icon" />
        </div>
        <div className="head progress-box">
          <h3>Settings</h3>
        </div>
        <div className="card-box-outer">
          <div className="card-head">
            <h5>Saved Cards</h5>
          </div>
          <div className="card-box-inner">
            <div className="d-flex justify-content-between card-box">
              <div className="card-detail w-auto" style={{maxWidth:"60%"}}>
                <span className="text-wrap">Ending in: ...6372</span>
                <span className="exp-date">Exp. date: 12/22</span>
              </div>
              <div className=" w-auto d-flex gap-2 gap-sm-4 align-items-center" >
                <img src={visacrdSmall} alt="Image" />
                <span className="delete-btn">
                  <img src={deleteIcon} alt="Icon" />
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between card-box">
              <div className="card-detail w-auto"  style={{maxWidth:"60%"}}>
                <span className="text-wrap">Ending in: ...6372</span>
                <span className="exp-date">Exp. date: 12/22</span>
              </div>
              <div className=" w-auto d-flex gap-2 gap-sm-4 align-items-center">
                <img src={mastercardSmall} alt="Image" />
                <span className="delete-btn">
                  <img src={deleteIcon} alt="Icon" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="links-outer d-inline-flex flex-column gap-4 mt-4">
          <Button
            className="blue-btn"
            onClick={() =>
              alert("This feature will be implemented in upcoming milestones")
            }
          >
            Add New Card
          </Button>
          <div
            className="setting-options"
            onClick={() => setProfileField("change-password")}
          >
            Change Password
          </div>
          <div className="setting-options" onClick={() => setOpenDeactivateModel(!openDeactivateModel)}>Deactivate Account</div>
          <div className="setting-options" onClick={() => setOpenDeleteModel(!openDeleteModel)}>Delete Account</div>
        </div>
      </div>

{openDeleteModel && <DeleteAccountModel deleteAccount={deleteAccount} openDeleteModel={openDeleteModel} setOpenDeleteModel={setOpenDeleteModel}/>}
{openDeactivateModel && <DeactiveAccountModel deactivateAccount={deactivate} openDeactivateModel={openDeactivateModel} setOpenDeactivateModel={setOpenDeactivateModel}/>}
    </div>


    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
};

export default ProfileSetting;
