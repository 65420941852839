import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Input, Label, Progress } from "reactstrap";
import "../WorkerPoolDefaultForm.scss";
import edit from "../../../assets/img/edit.png";
import buld from "../../../assets/img/circle-building.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addexperiencedetails,
  getLocationsData,
  getNobelUserProfile,
  getWPUserProfileById,
  removeProfileSubData,
  updateWorkerUserDetails,
} from "../../../store/profile/ProfileAction";
import { call } from "../../../api/apiCall";
import Autocomplete from "react-autocomplete";
import { getAllProfession } from "../../../store/dashboard/dashboardAction";
import DatePickerCustom from "../../../core/DatePicker/DatePickerReact";
import backArrow from "../../../assets/img/backArrow.svg";
import HeaderLogo from "../../Header/header";
import { toast } from "react-toastify";
import Loader from "../../../core/Loaders/ComponentLoader";
import dustbin from "../../../assets/img/dustbin.svg";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
const WorkExperience = (props) => {
  let {
    setCountinue,
    user,
    errorROE,
    setErrorROE,
    experienceRegion,
    isProfilePage,
    setProfileField,
    selectedCategory,
    setSelectedCategory,
    catergoryList,
    professionList,
    token,
    workerId,
  } = props;
  const { nobelprofileData } = useSelector((state) => state.profile);
  const titleRef = useRef();
  const [editExperienceData, setEditExperienceData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [expData, setExpData] = useState({
    title: "",
    company_name: "",
    start_date: null,
    end_date: null,
    is_current: false,
    is_visible: true,
    category: "",
    profession: "",
    region: "",
  });

  const [errorTitle, setErrorTitle] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [errorCategory, setErrorCategory] = useState("");
  const [errorProfession, setErrorProfession] = useState("");
  const [errorCompany, setErrorCompany] = useState("");
  token = token || localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loader);
  useEffect(() => {
    let id = user?.worker?.id;
    if (workerId) {
      dispatch(getWPUserProfileById(workerId, token)).then((response) => {
        if (response?.success === false) return;
      });
    } else if (id) {
      dispatch(getWPUserProfileById(id, token)).then((response) => {
        if (response?.success === false) return;
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      getAllProfession({ category: selectedCategory ? selectedCategory : null })
    );
  }, [selectedCategory]);
  useEffect(() => {
    if (editExperienceData) {
      setExpData({
        id: editExperienceData?.id,
        title: editExperienceData?.title,
        company_name: editExperienceData?.company_name,
        start_date: editExperienceData?.start_date
          ? moment(editExperienceData?.start_date).utc().format("YYYY-MM-DD")
          : "",
        end_date: editExperienceData?.end_date
          ? moment(editExperienceData?.end_date).utc().format("YYYY-MM-DD")
          : "",
        is_current: editExperienceData?.is_current,
        category: editExperienceData?.category,
        profession: editExperienceData?.profession,
        region: editExperienceData?.region,
      });
      setSelectedCategory(editExperienceData?.category);
    }
  }, [editExperienceData]);

  useEffect(() => {
    // (async () => {
    //   try {
    //     const res = await call("get", "api/v1/employment-type", null, null);
    //     setCompanyType(res.data);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // })();

    // setShowLabel(expData.employment_type);
    getCategory();
  }, []);

  const checkValidation = (type) => {
    let formIsValid = true;
    let stringName = /^[^0-9][a-zA-Z0-9\.\,\+\-_\s]+$/;
    if (type === "title" || type === "all") {
      if (!expData?.title?.trim()) {
        setErrorTitle("Mandatory Field");
        formIsValid = false;
        return;
      } else if (!stringName.test(expData?.title)) {
        setErrorTitle(
          "Title should not start with numbers and not include special charaters"
        );
        formIsValid = false;
        return;
      } else {
        setErrorTitle("");
      }
    }
    if (type === "company" || type === "all") {
      if (!expData?.company_name) {
        setErrorCompany("Mandatory Field");
        formIsValid = false;
        return;
      } else {
        setErrorCompany("");
        formIsValid = true;
      }
    }

    if (type === "category" || type === "all") {
      if (!selectedCategory) {
        setErrorCategory("Mandatory Field");
        formIsValid = false;
        return;
      } else {
        setErrorCategory("");
        formIsValid = true;
      }
    }
    if (type === "profession" || type === "all") {
      if (!expData?.profession) {
        setErrorProfession("Mandatory Field");
        formIsValid = false;
        return;
      } else {
        setErrorProfession("");
        formIsValid = true;
      }
    }
    if (type === "date" || type === "all") {
      if (!expData.start_date) {
        setErrorDate("Mandatory Field");
        formIsValid = false;
      } else if (expData.is_current == false && expData.start_date) {
        if (!expData.end_date) {
          setErrorDate("End Date is required.");
          formIsValid = false;
        }
      } else {
        setErrorDate("");
      }
    }

    return formIsValid;
  };
  const totalExp = (start, end) => {
    var today = new Date();
    var startDate = new Date(start);
    var endDate = new Date(end ? end : today);
    var startMonth = startDate.getFullYear() * 12 + startDate.getMonth();
    var endMonth = endDate.getFullYear() * 12 + endDate.getMonth();
    var monthInterval = endMonth - startMonth;
    var yearsOfExperience = Math.floor(monthInterval / 12);
    var monthsOfExperience = monthInterval % 12;
    return yearsOfExperience + "." + monthsOfExperience;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      let newData = expData;
      Object.keys(newData).forEach((key) => {
        if (key === "is_visible") return key;
        else if (!newData[key]) delete newData[key];
      });
      if (workerId)
        dispatch(
          updateWorkerUserDetails(
            {
              experiences: [expData],
            },
            token,
            nobelprofileData?.id
          )
        )
          .then((res) => {
            if (res?.success) {
              {
                isEdit
                  ? toast.success("Experience updated Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    })
                  : toast.success("Experience added Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    });
              }

              if (editExperienceData) {
                setEditExperienceData({});
                setIsEdit();
              }
              setExpData({
                title: "",
                company_name: "",
                start_date: null,
                end_date: null,
                is_current: false,
                is_visible: true,
                category: "",
                profession: "",
                region: "",
              });
              setListImage("");
            }
          })
          .catch((e) => {
            console.log(e);
            alert("Something went wrong.");
          });
      else
        dispatch(addexperiencedetails(expData, token))
          .then((res) => {
            if (res?.success) {
              {
                isEdit
                  ? toast.success("Experience updated Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    })
                  : toast.success("Experience added Successfully", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    });
              }

              if (editExperienceData) {
                setEditExperienceData({});
                setIsEdit();
              }
              setExpData({
                title: "",
                company_name: "",
                start_date: null,
                end_date: null,
                is_current: false,
                is_visible: true,
                category: "",
                profession: "",
                region: "",
              });
              setListImage("");
            }
          })
          .catch((e) => {
            console.log(e);
            alert("Something went wrong.");
          });
    }
  };
  const getCategory = () => {
    dispatch(getAllProfession());
  };

  const handleDelete = (id) => {
    dispatch(removeProfileSubData("experience", id));
  };

  const [companyList, setCompanyList] = useState([]);
  const [listImage, setListImage] = useState("");

  const getCompanyList = (value) => {
    (async () => {
      try {
        const res = await call(
          "get",
          `api/v1/public/page-list?search=${value}`,
          null,
          null
        );
        console.log(res, "fghfghgffgrhfghfgrh");
        if (res?.data?.success) {
          // console.log(res.data);
          setCompanyList(res?.data?.data);
          console.log(res.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  };

  return (
    <>
      {isLoading && <AppLoader />}
      {!isProfilePage && !workerId && <HeaderLogo />}
      <div
        className={
          "work-experience-page workerpool-default-form-page" +
          (isProfilePage ? " my-4" : "")
        }
      >
        <div className="container d-flex flex-column align-items-center">
          <div className="width-850">
            {isProfilePage && (
              <div className="workerpool-default-form-page">
                <div
                  className="back-btn"
                  onClick={() => setProfileField("profile")}
                >
                  <img src={backArrow} alt="Icon" />
                </div>
              </div>
            )}
            <div className="head progress-box">
              <h3 className="mb-2">Work Experience</h3>
              {!isProfilePage && <Progress value={60} />}
            </div>
            <form className="form-box">
              <div className="row">
                <FormGroup className="col-sm-8">
                  <Label>Job Title</Label>
                  <Input
                    ref={titleRef}
                    onClick={(e) => setErrorTitle("")}
                    maxLength="50"
                    onBlur={() => {
                      checkValidation("title");
                    }}
                    value={expData?.title}
                    onChange={(e) =>
                      setExpData({ ...expData, title: e.target.value })
                    }
                    type="text"
                    placeholder="Enter job title"
                  />
                  {errorTitle && (
                    <label className="label-input100 text-danger">
                      {errorTitle}
                    </label>
                  )}
                </FormGroup>
                <FormGroup className="col-sm-4">
                  <Label>Employer</Label>
                  {/* <Input
                    value={expData.company_name}
                    maxLength="80"
                    onClick={(e) => setErrorCompany("")}
                    onBlur={() => checkValidation("company")}
                    placeholder="Company name"
                    onChange={(e) =>
                      setExpData({ ...expData, company_name: e.target.value })
                    }
                  /> */}

                  <Autocomplete
                    className="location"
                    inputProps={{
                      placeholder: "Enter employer name",
                    }}
                    maxLength={50}
                    value={expData?.company_name}
                    items={companyList}
                    getItemValue={(item) => item.name}
                    // shouldItemRender={(item, value) =>
                    //   item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                    // }
                    renderMenu={(items, value) => (
                      <div
                        className="dropdown bg-white p-3"
                        style={{
                          height: "150px",
                          overflow: "auto",
                          zIndex: 999,
                          border: "1px solid #d1dfff",
                        }}
                      >
                        {items.length === 0 ? (
                          `No matches for ${value}`
                        ) : items.length >= 50 ? (
                          <p style={{ cursor: "pointer" }}>
                            {items.slice(0, 50)}...
                          </p>
                        ) : (
                          <p style={{ cursor: "pointer" }}>{items}</p>
                        )}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item mt-2 d-flex align-items-center ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                        style={{ display: "flex" }}
                      >
                        <img
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                          }}
                          src={item.icon ? item.icon : buld}
                          alt=""
                        />
                        <div className="mx-2">
                          <div className="name-heading-drop ">{item.name}</div>
                        </div>
                      </div>
                    )}
                    onChange={(obj, val) => {
                      console.log(val, "val");
                      getCompanyList(val);
                      setExpData({ ...expData, company_name: val });
                      setListImage(obj.icon || buld);
                    }}
                    onSelect={(val, obj) => {
                      setListImage(obj.icon || buld);
                      setExpData({
                        ...expData,
                        company_name: val,
                      });
                    }}
                    wrapperStyle={{}}
                    renderInput={(params) => {
                      return (
                        <div
                          class="fake-input"
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            border: "1px solid var(--borderline, #d1dfff)",
                            borderRadius: "5px",
                          }}
                        >
                          <input
                            {...params}
                            type="text"
                            placeholder="Company name"
                            style={{ border: "none" }}
                          />

                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "50%",
                            }}
                            src={listImage || buld}
                            alt=""
                          />
                        </div>
                      );
                    }}
                  />

                  {errorCompany && (
                    <label className="label-input100 text-danger">
                      {errorCompany}
                    </label>
                  )}
                </FormGroup>
                <FormGroup className="col-sm-6">
                  <Label>Category</Label>
                  <select
                    className="form-select"
                    value={expData.category}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      setExpData({
                        ...expData,
                        category: e.target.value,
                      });
                      setErrorCategory("");
                    }}
                  >
                    <option value="">Select</option>
                    {catergoryList?.map((item, index) => (
                      <option value={item?.category} key={index}>
                        {item?.category}
                      </option>
                    ))}
                  </select>
                  {errorCategory && (
                    <label className="label-input100 text-danger">
                      {errorCategory}
                    </label>
                  )}
                </FormGroup>
                <FormGroup className="col-sm-6">
                  <Label>Profession</Label>
                  <select
                    className="form-select"
                    options={professionList}
                    onChange={(e) =>
                      setExpData({
                        ...expData,
                        profession: e.target.value,
                      })
                    }
                    disabled={
                      selectedCategory || expData.category ? false : true
                    }
                    value={expData?.profession}
                  >
                    <option value="">Select</option>
                    {professionList?.map((item, index) => (
                      <option value={item?.name} key={index}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  {errorProfession && (
                    <label className="label-input100 text-danger">
                      {errorProfession}
                    </label>
                  )}
                </FormGroup>

                {/* <div className="col-sm-7">
                  <div className="row"> */}
                <FormGroup className="col-sm-4">
                  <Label>Join Date</Label>
                  <DatePickerCustom
                    value={
                      expData?.start_date ? new Date(expData?.start_date) : ""
                    }
                    onChange={(date) => {
                      date
                        ? setExpData({
                            ...expData,
                            start_date: moment(date).format("YYYY-MM-DD"),
                            end_date: "",
                          })
                        : setExpData({
                            ...expData,
                            start_date: "",
                            end_date: "",
                          });
                      setErrorDate("");
                    }}
                    leftAlign={true}
                    onClick={() => setErrorDate("")}
                    placeholder="Select"
                    max={new Date()}
                  />
                </FormGroup>
                <FormGroup className="col-sm-4">
                  <Label>End Date</Label>
                  <DatePickerCustom
                    value={expData?.end_date ? new Date(expData?.end_date) : ""}
                    onChange={(date) => {
                      date
                        ? setExpData({
                            ...expData,
                            end_date: moment(date).format("YYYY-MM-DD"),
                          })
                        : setExpData({ ...expData, end_date: "" });
                      setErrorDate("");
                    }}
                    placeholder="Select"
                    leftAlign={true}
                    onClick={() => setErrorDate("")}
                    disabled={
                      expData?.start_date === "" || expData?.is_current === true
                        ? true
                        : false
                    }
                    min={
                      expData?.start_date ? new Date(expData?.start_date) : ""
                    }
                    max={new Date()}
                  />
                  {errorDate && (
                    <label className="label-input100 text-danger">
                      {errorDate}
                    </label>
                  )}
                </FormGroup>
                {/* </div>
                </div>
                <div className="col-sm-5 text-end align-self-center mt-2"> */}
                <FormGroup className="col-sm-4 col-lg-2 mb-3 px-sm-0 px-md-2 d-flex">
                  <Label className="align-self-end">
                    <Input
                      type="checkbox"
                      style={{ marginTop: "0px" }}
                      checked={expData?.is_current}
                      onChange={(e) =>
                        setExpData({
                          ...expData,
                          is_current: e.target.checked,
                          end_date: "",
                        })
                      }
                    />
                    No end date
                  </Label>
                </FormGroup>
                <div className="col-12">
                  <div className="justify-content-between row">
                  <FormGroup className="col-sm-6">
                    <Label>Region</Label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setExpData({
                          ...expData,
                          region: e.target.value,
                        });
                        setErrorROE("");
                      }}
                      value={expData.region}
                    >
                      <option value="0">Select</option>
                      {experienceRegion?.map((item, index) => (
                        <option value={item?.id} key={index}>
                          {item?.name} {","}
                          {item?.region}
                        </option>
                      ))}
                    </select>
                    {errorROE && (
                      <label className="label-input100 text-danger">
                        {errorROE}
                      </label>
                    )}
                  </FormGroup>
                  <div className="col-12 col-sm-6 col-lg-2 mb-3 text-end">
                    <div
                      className="btn btn-blue col-12 col-sm-auto"
                      style={{ marginTop: "30px", minWidth: "119px", "@media screen and (max-width:767px)":{
                        width:"100%",
                      } }}
                      onClick={(e) => handleSubmit(e)}
                    >
                      {isEdit ? "Save" : "Add Work"}
                    </div>
                  </div>
                </div>
                </div>
                

                {/* </FormGroup> */}
                {/* </div> */}

                <div className="col-sm-12 my-5">
                  {nobelprofileData?.user?.experiences && (
                    <div className="line"></div>
                  )}
                  {nobelprofileData?.user?.experiences?.map((item, index) => (
                    <div className="edit-box" key={index}>
                      {console.log(item)}
                      <div className="d-flex justify-content-between">
                        <h4>{item?.title}</h4>
                        <div className="d-flex">
                          <span
                            className="edit-btn"
                            onClick={() => {
                              setEditExperienceData(item);
                              setIsEdit(item.id);
                            }}
                          >
                            {isEdit != item.id && <img src={edit} alt="Icon" />}
                          </span>

                          <span
                            className="edit-btn mx-3"
                            onClick={() => handleDelete(item.id)}
                          >
                            <img src={dustbin} />
                          </span>
                        </div>
                      </div>
                      <h5>
                        {item?.company_name} (
                        {totalExp(item?.start_date, item?.end_date)}{" "}
                        {totalExp(item?.start_date, item?.end_date) > 1
                          ? "years"
                          : "year"}
                        )
                      </h5>
                    </div>
                  ))}
                </div>
              </div>
              {isProfilePage ? (
                <></>
              ) : (
                // <div className="text-center my-3">
                //   <div className="btn btn-blue" onClick={() => handleSave()}>
                //     Save
                //   </div>
                // </div>
                <div className="text-end d-flex gap-lg-3 gap-2 flex-wrap justify-content-md-between justify-content-center">
                  <Button
                    className="btn-outline no-bg flex-grow-1 flex-md-grow-0"
                    onClick={() => setCountinue(1)}
                  >
                    Back
                  </Button>
                  <div className="d-flex flex-column flex-md-row justify-content-between justify-content-md-end align-items-center flex-grow-1 flex-md-grow-0  gap-md-3 gap-2  skip">
                    <span
                      className="skip_btn text-center"
                      onClick={() => setCountinue(3)}
                    >
                      Skip
                    </span>
                    <Button
                      className="btn-blue"
                      onClick={() => setCountinue(3)}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkExperience;
