import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { seekerRegister } from "../../../store/auth/authActions";
import { authApi } from "../../../api/authAPI";
import HeaderLogo from "../../Header/header";
import { call } from "../../../api/apiCall";
import FormInput from "../../../core/Inputs/FormInput";
import { toast } from "react-toastify";

const AddSeeker = ({ pageNo, setPageNo }) => {
  let [data, setData] = useState({
    email: "",
    password: "",
    confirmPass: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPass: "",
  });

  const dispatch = useDispatch();
  const [emailexistence, setEmailExistence] = useState(true);

  const handleValidation = (type) => {
    let formValid = true;
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // if (type == "email" || type == "all") {
    //   if (!data?.email) {
    //     formValid = false;
    //     setError({ ...error, email: "Email is required" });
    //     return;
    //   } else if (!emailvalid.test(data?.email)) {
    //     setError({
    //       ...error,
    //       email: "Please enter your email in a valid format.",
    //     });
    //     formValid = false;
    //   } else if (data?.email) {
    //     emailExist(data?.email).then((response) => {
    //       if (!response) {
    //         console.log(typeof response);
    //         // if (response.message === "Already exist") {
    //         setError({
    //           ...data,
    //           email:
    //             "Email already exists on Nobel Page, Please continue as Nobel Page",
    //         });
    //         setEmailExistence(false);
    //         formValid = false;
    //         // }
    //       } else {
    //         setError({ ...error, email: "" });
    //         setEmailExistence(true);
    //       }
    //     });
    //   } else setError({ ...error, email: "" });
    // }
    // // if (type == "password" || type == "all") {
    // //   if (!data?.password || data?.password.length < 8) {
    // //     formValid = false;
    // //     setError({ ...error, password: "Password is required" });
    // //     return;
    // //   } else setError({ ...error, password: "" });
    // // }
    // if (type === "password" || type === "all") {
    //   if (!data?.password) {
    //     setError({ ...error, password: "Please enter your password." });
    //     formValid = false;
    //   } else if (!passwordValid.test(data?.password)) {
    //     setError({
    //       ...error,
    //       password:
    //         'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]',
    //     });
    //     formValid = false;
    //   } else {
    //     setError({ ...error, password: "" });
    //   }
    // }
    // if (type == "confirmPass" || type == "all") {
    //   if (!data.confirmPass) {
    //     formValid = false;
    //     setError({ ...error, confirmPass: "Confirm Password is required" });
    //   } else if (data?.password !== data?.confirmPass) {
    //     formValid = false;
    //     setError({
    //       ...error,
    //       confirmPass: "Confirm Password should match to password",
    //     });
    //     return;
    //   } else setError({ ...error, confirmPass: "" });
    // }

    if (type === "all" || type === "email") {
      if (!data.email) {
        setError({ ...error, email: "Email is required" });
        formValid = false;
        return;
      } else if (!emailvalid.test(data.email)) {
        setError({
          ...error,
          email: "Please enter your email in a valid format.",
        });
        formValid = false;
        return;
      } else if (data.email) {
        emailExist(data.email).then((response) => {
          console.log(response);
          if (response === false) {
            setError({
              ...error,
              email:
                "Email already exists on Nobel Page, Please continue as Nobel Page",
            });
            setEmailExistence(false);
            formValid = false;
            return;
          } else {
            setError({ ...error, email: "" });
            setEmailExistence(true);
          }
        });
      } else setError({ ...error, email: "" });
    }
    if (type === "all" || type === "password") {
      if (!data.password) {
        formValid = false;
        setError({ ...error, password: "Password is required" });
        return;
      } else if (!passwordValid.test(data?.password)) {
        setError({
          ...error,
          password:
            'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]',
        });
        formValid = false;
        return;
      } else setError({ ...error, password: "" });
    }

    if (type == "confirmPass" || type == "all") {
      if (!data.confirmPass) {
        formValid = false;
        setError({ ...error, confirmPass: "Confirm Password is required" });
        return;
      } else if (data?.password !== data?.confirmPass) {
        formValid = false;
        setError({
          ...error,
          confirmPass: "Confirm Password should match to password",
        });
        return;
      } else setError({ ...error, confirmPass: "" });
    }

    return formValid;
  };

  const emailExist = (emailName) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/auth/email-exist/${emailName}`,
            null,
            null,
            null
          );
          resolve(true);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation("all")) {
      dispatch(seekerRegister({ email: data?.email, password: data?.password }))
        .then((res) => {
          if (res?.success) {
            authApi
              .sendOtp(res?.token, { email: res?.data?.email })
              .then((res) => {
                if (res?.success) {
                  setPageNo(1);
                  setData({ email: "", password: "" });
                }
              })
              .catch((err) => toast.error(err?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      <HeaderLogo />
      <div className="job-preview-page workerpool-default-form-page">
        <div className="container">
          <div className="width-850 m-auto">
            <div className="head">
              <span className="head-title">Signup Information</span>
            </div>
            <form>
              <div className="row">
                <div className="col-12 mb-3">
                  {/* <FormGroup>
                    <label htmlFor="email">Email Id</label>
                    <Input
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={data?.email}
                      onChange={(e) => handleChange(e)}
                      onBlur={() => handleValidation("email")}
                    />
                    {error?.email && (
                      <label className="text-danger">{error?.email}</label>
                    )}
                  </FormGroup> */}
                  <FormInput
                    label="Email Id"
                    type="email"
                    name="email"
                    value={data?.email}
                    onChange={(e) => handleChange(e)}
                    onBlur={() => handleValidation("email")}
                    error={error?.email}
                   
                  />
                </div>

                <div className="col-md-6 col-12">
                  <FormGroup>
                    {/* <label htmlFor="password">Password</label> */}
                    <FormInput
                      label={"Password"}
                      type="password"
                      placeholder="Enter password"
                      name="password"
                      value={data?.password}
                      onChange={(e) => handleChange(e)}
                      onBlur={() => handleValidation("password")}
                    
                    />
                    {error?.password && (
                      <label className="text-danger">{error?.password}</label>
                    )}
                  </FormGroup>
                </div>

                <div className="col-md-6 col-12">
                  <FormGroup>
                    {/* <label htmlFor="password">Confirm Password</label> */}
                    <FormInput
                      label={"Confirm Password"}
                      type="password"
                      placeholder="Enter password"
                      name="confirmPass"
                      value={data?.confirmPass}
                      onChange={(e) => handleChange(e)}
                      onBlur={() => handleValidation("confirmPass")}
                      className="mb-0"
                    />
                    {error?.confirmPass && (
                      <label className="text-danger">
                        {error?.confirmPass}
                      </label>
                    )}
                  </FormGroup>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <button
                  type="submit"
                  className="submit_btn px-3 py-2 w-100"
                  style={{maxWidth:"186px"}}
                  onClick={(e) => handleSubmit(e)}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSeeker;
