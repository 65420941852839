export const GET_WORKER_LIST = "GET_WORKER_LIST";
export const FAIL_GET_WORKER_LIST = "FAIL_GET_WORKER_LIST";
export const GET_STATIC_DATA = "GET_STATIC_DATA";
export const GET_WORKER_PROFILE = "GET_WORKER_PROFILE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_NP_SUCCESS = "LOGIN_NP_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const INIT_FAIL = "INIT_FAIL";
export const INITIALIZE_THE_LOADER = "INITIALIZE_THE_LOADER";
export const STOP_THE_LOADER = "STOP_THE_LOADER";
export const INITIALIZE_THE_PAGE_LOADER = "INITIALIZE_THE_PAGE_LOADER";
export const STOP_THE_PAGE_LOADER = "STOP_THE_PAGE_LOADER";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const GET_EXP_REGION = "GET_EXP_REGION";
export const GET_EMPLOYMENT_TYPES = "GET_EMPLOYMENT_TYPES";
export const TOKEN_SUCCESS = "TOKEN_SUCCESS";
export const USER_AFTER_NP_LOGIN = "USER_AFTER_NP_LOGIN";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAIL = "GET_LOCATIONS_FAIL";
export const GET_RELIGION_LIST = "GET_RELIGION_LIST";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_PROFESSION_LIST = "GET_PROFESSION_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const ADD_EDUCATION_DATA = "ADD_EDUCATION_DATA";
export const UPDATE_EDUCATION_DATA = "UPDATE_EDUCATION_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const UPDATE_EXPERIENCE_DATA = "UPDATE_EXPERIENCE_DATA";
export const ADD_EXPERIENCE_DATA = "ADD_EXPERIENCE_DATA";
export const UPDATE_LICENSE_DATA = "UPDATE_LICENSE_DATA";
export const ADD_LICENSE_DATA = "ADD_LICENSE_DATA";
export const ADD_LANGUAGE_DATA = "ADD_LANGUAGE_DATA";
export const UPDATE_LANGUAGE_DATA = "UPDATE_LANGUAGE_DATA";
export const GET_DATA_BY_ID = "GET_DATA_BY_ID";
export const UPLOAD_INTRO_VIDEO = "UPLOAD_INTRO_VIDEO";
export const DELETE_INTRO_VIDEO = "DELETE_INTRO_VIDEO";
export const UPDATE_NOBEL_DATA = "UPDATE_NOBEL_DATA";
export const UPLOAD_RESUME_DATA = "UPLOAD_RESUME_DATA";
export const PUBLISH_AS_PUBLIC = "PUBLISH_AS_PUBLIC";
export const INIT_WT_SUCCESS = "INIT_WT_SUCCESS";
export const GET_WP_DATA_BY_ID = "GET_WP_DATA_BY_ID";
export const UPDATE_WORKER_INFO_DATA = "UPDATE_WPRKER_INFO_DATA";
export const ADD_BUSINESS_PAGE = "ADD_BUSINESS_PAGE";
export const GET_MANAGEWORKER_LIST = "GET_MANAGEWORKER_LIST";
export const UPDATE_MANAGEWORKER_PROFILE = "UPDATE_MANAGEWORKER_PROFILE";
export const DELETE_MANAGEWORKER_PROFILE = "DELETE_MANAGEWORKER_PROFILE";
export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_JOB_DETAIL = "GET_JOB_DETAIL";
export const APPLY_JOB = "APPLY_JOB";
export const APPLY_JOB_DETAIL = "APPLY_JOB_DETAIL";
export const SAVED_JOB = "SAVED_JOB";
export const GET_MY_JOB_LIST = "GET_MY_JOB_LIST";
export const UPDATE_JOB = "UPDATE_JOB";
export const GET_JOB_APPLICANT = "GET_JOB_APPLICANT";
export const UPDATE_JOB_DETAIL = "UPDATE_JOB_DETAIL";
export const REMOVE_EXPERIENCE = "REMOVE_EXPERIENCE";
export const REMOVE_EDUCATION = "REMOVE_EDUCATION";
export const REMOVE_LICENSE = "REMOVE_LICENSE";
export const REMOVE_LANGUAGE = "REMOVE_LANGUAGE";
export const GET_SEARCH_HISTORY = "GET_SEARCH_HISTORY";
export const GET_AD_LIST = "GET_AD_LIST";
