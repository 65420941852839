import React, { useState } from "react";
import HeaderLogo from "../Header/header";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./CreateSeeker.scss";
import smallWhiteLogo from "../../assets/img/npIcon.svg";
import { NavLink, useNavigate } from "react-router-dom";
import AskNobePage from "../login/component/AskNobePage";
import guestIcon from "../../assets/img/guest.svg";
const CreateSeeker = () => {
  const [askNobelModal, setAskNobelModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <HeaderLogo />

      <Modal isOpen={true} size="lg" centered className="profilePermission">
        {/* <ModalHeader >
          
        </ModalHeader> */}

        <ModalBody className="p-3">
          <div className="modal-header d-flex flex-column  p-lg-5 p-md-4 p-3 py-4  text-white" >
            <h3 className="text-center Modal-heading" style={{maxWidth:"460px"}}>Choose your options to Login</h3>
            <p className="text-center mt-3" style={{maxWidth:"460px"}}>
              If you're looking for Workers, sign in to access all benefits and
              visibility of worker public and private listed profiles. Use your
              NobelPage Scout account or register alternatively as a guest to
              unlock the contact details of our public listed worker profiles.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-wrap justify-content-center align-items-center gap-3 pt-4 pb-3">
              <div
                className="noble-login-btns"
                onClick={() => setAskNobelModal(true)}
                style={{ border: "1px solid #29409C", height:"43px" }}
              >
                <img src={smallWhiteLogo} alt="logo" />
                Connect NobelPage
              </div>

              <div
                className="noble-login-btns"
                onClick={() => navigate("/worker/add-seeker")}
                style={{ border: "1px solid #29409C", height:"43px" }}
              >
                <img src={guestIcon} alt="logo" />
                Continue as guest
              </div>
            </div>

            <NavLink to="/worker/account-type" className="backBtn">
              Back
            </NavLink>
          </div>
        </ModalBody>

        {/* <ModalBody>

        </ModalBody> */}
      </Modal>

      {askNobelModal && (
        <AskNobePage
          modal={askNobelModal}
          toggle={() => setAskNobelModal(!askNobelModal)}
          type="seeker"
          isLogin="false"
        />
      )}
    </>
  );
};

export default CreateSeeker;
