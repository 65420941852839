import React, { useState } from "react";
import backArrow from "../../../assets/img/backArrow.svg";
import HeaderLogo from "../../Header/header";
import { Button, FormGroup, Input, Label, Progress } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import WhatsappIcon from "../../../assets/img/coloredwhatsapp.svg";
import "./BusinessPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { AddCompany } from "../../../store/profile/ProfileAction";
import Loader from "../../../core/Loaders/ComponentLoader";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
const BusinessPage = ({ isProfilePage, user, setProfileField }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [data, setData] = useState({
    name: "",
    email: user?.email || "",
    phone: "",
    whatsapp: "",
    website: "",
    sameAsPhone: false,
  });

  const { isLoading } = useSelector((state) => state.loader);

  let [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const deleteData = (e) => {
    e.preventDefault();
    setData({
      name: "",
      email: "",
      phone: "",
      whatsapp: "",
      website: "",
      sameAsPhone: false,
    });
  };

  const addPage = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      delete data?.sameAsPhone;
      dispatch(AddCompany({ ...data, category: "company" }))
        .then((res) => {
          if (res?.success) {
            toast.success(res?.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setProfileField("profile");
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message || e?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const checkValidation = (type) => {
    let formIsValid = true;

    if (type === "name" || type === "all") {
      if (!data?.name?.trim()) {
        formIsValid = false;
        setError({ ...error, name: "Name is mandatory." });
        return;
      } else {
        setError({ ...error, name: "" });
      }
    }

    if (type === "email" || type === "all") {
      if (!data.email.trim()) {
        formIsValid = false;
        setError({ ...error, email: "Email is mandatory." });
        return;
      } else {
        setError({ ...error, email: "" });
      }
    }
    if (type === "phone" || type === "all") {
      if (!data.phone) {
        formIsValid = false;
        setError({ ...error, phone: "Phone  is mandatory" });
        return;
      } else {
        setError({ ...error, phone: "" });
      }
    }

    if (type === "whatsapp" || type === "all") {
      if (!data.whatsapp) {
        formIsValid = false;
        setError({ ...error, whatsapp: "Whatsapp is mandatory" });
        return;
      } else {
        setError({ ...error, whatsapp: "" });
      }
    }

    if (type === "website" || type === "all") {
      if (!data.website.trim()) {
        formIsValid = false;
        setError({ ...error, website: "Website is mandatory" });
        return;
      } else {
        setError({ ...error, website: "" });
      }
    }

    return formIsValid;
  };

  return (
    <>
      {!isProfilePage && <HeaderLogo />}
      {isLoading && <AppLoader />}
      <div
        className={
          "education-info-page workerpool-default-form-page px-2" +
          (isProfilePage ? " my-4" : "")
        }
      >
        <div className="container">
          <div className="width-850">
            {isProfilePage && (
              <div className="workerpool-default-form-page">
                <div
                  className="back-btn"
                  onClick={() => setProfileField("profile")}
                >
                  <img src={backArrow} alt="Icon" />
                </div>
              </div>
            )}
            <div className="head progress-box">
              <h3>Add Business Page</h3>
              {/* {!isProfilePage && <Progress value={40} />} */}
            </div>
            <form className="form-box">
              <div className={"row" + (isProfilePage ? " mb-5" : "")}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <Input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="name"
                        placeholder="Enter name"
                        value={data?.name}
                        onBlur={() => checkValidation("name")}
                        maxLength={50}
                      />
                      {error?.name && (
                        <label className="label-input100 text-danger">
                          {error?.name}
                        </label>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-6 col-12">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <Input
                        type="email"
                        onChange={(e) => handleChange(e)}
                        name="email"
                        placeholder="Enter email"
                        value={data?.email}
                        onBlur={() => checkValidation("email")}
                      />
                      {error?.email && (
                        <label className="label-input100 text-danger">
                          {error?.email}
                        </label>
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-12">
                    <FormGroup>
                      <label  className="form-label">Phone</label>
                      <PhoneInput
                        country={"us"}
                        // Default country
                        value={data?.phone}
                        onChange={(e) => setData({ ...data, phone: e })}
                        placeholder="Enter phone"
                        onBlur={() => checkValidation("phone")}
                      />
                      {error?.phone && (
                        <label className="label-input100 text-danger">
                          {error?.phone}
                        </label>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-6 col-12">
                    <FormGroup>
                      <div className="d-flex justify-content-between">
                        <label className="form-label">
                          Whatsapp <img src={WhatsappIcon} />
                        </label>
                        <div className="mb-2">
                          <Input
                            type="checkbox"
                            name="sameAsPhone"
                            onChange={() =>
                              setData({
                                ...data,
                                sameAsPhone: !data.sameAsPhone,
                                whatsapp: !data?.sameAsPhone ? data?.phone : "",
                              })
                            }
                            value={data.sameAsPhone}
                            style={{ color: "#676A79", fontSize: "14px" }}
                          />
                          Same as Phone
                        </div>
                      </div>
                      <PhoneInput
                        country={"us"} // Default country
                        value={
                          data.phone && data.sameAsPhone
                            ? data.phone
                            : data.whatsapp
                        }
                        disabled={data.sameAsPhone}
                        onChange={(e) => setData({ ...data, whatsapp: e })}
                        placeholder="Enter phone"
                        onBlur={() => checkValidation("whatsapp")}
                      />
                      {error?.whatsapp && (
                        <label className="label-input100 text-danger">
                          {error?.whatsapp}
                        </label>
                      )}
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FormGroup>
                      <label className="form-label">Link</label>
                      <Input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="website"
                        placeholder="Link"
                        value={data?.website}
                        onBlur={() => checkValidation("website")}
                      />
                      {error?.website && (
                        <label className="label-input100 text-danger">
                          {error?.website}
                        </label>
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <div
                    className="delete_btn d-flex justify-content-center align-items-center"
                    onClick={deleteData}
                  >
                    Delete
                  </div>
                  <button
                    type="submit"
                    className="add_btn mx-2"
                    onClick={(e) => addPage(e)}
                  >
                    Add
                  </button>
                </div>
              </div>
              {/* {!isProfilePage && (
                <div className="text-end my-5">
                  <Button
                    className="btn-outline no-bg mx-0 mx-md-4 mb-5"
                    //   onClick={() => {
                    //     isLoggedIn ? navigate("/") : setCountinue(0);
                    //   }}
                  >
                    Back
                  </Button>
                  <Button
                    className="btn-blue mb-5"
                    //   onClick={() => setCountinue(2)}
                  >
                    Continue
                  </Button>
                </div>
              )} */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessPage;
