import axios from "axios";
import { call } from "./apiCall";
import { toast } from "react-toastify";

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

class AuthApi {
  loginWithNP(emailName, password, registration_type) {
    return new Promise((resolve, reject) => {
      let loginData = {
        email: emailName,
        password: password,
        registration_type: registration_type,
      };

      (async () => {
        try {
          const res = await call("post", "api/v1/auth/login", null, loginData);
          if (res?.data?.success) {
            const accessToken = res?.data?.token;
            // setSession(accessToken);
            resolve(res.data);
          } else {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  login(emailName, password, rememberMe) {
    return new Promise((resolve, reject) => {
      let loginData = {
        email: emailName,
        password: password,
        rememberMe,
        // registration_type: registration_type
      };

      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/worker/login",
            null,
            loginData
          );
          if (res?.data?.succes) {
            const accessToken = res?.data?.token;
            setSession(accessToken);
            resolve(res.data);
          }
          resolve(res?.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  register(signUpData) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/worker/signup",
            null,
            signUpData
          );
          const accessToken = res?.data?.token;
          console.log(res?.data, res, accessToken);
          setSession(accessToken);
          if (res?.data?.message === "Worker pool created successfully") {
            toast.success("You have successfully signup in workerpool", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          resolve(res?.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            "api/v1/auth/logout",
            null,
            null,
            token
          );
          localStorage.clear();
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  updateDetails(formdata) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            "/update-user",
            null,
            formdata,
            token
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getpublictoken() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/public/get-token?web=true",
            null,
            null,
            null
          );
          if (res?.data?.success) {
            const accessToken = res?.data?.data;
            setSession(accessToken);
            resolve(res.data);
          }

          resolve(res?.data);
        } catch (err) {
          resolve(null);
        }
      })();
    });
  }

  getuserbytoken() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/user/get_by_token",
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(null);
        }
      })();
    });
  }

  getNPuserbytoken(token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/user/get_by_token",
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(null);
        }
      })();
    });
  }

  providerSignup(type, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/worker/create?type=${type}`,
            null,
            {},
            token
          );
          // const accessToken = res?.data?.token;
          // setSession(accessToken);
          if (res?.data?.message === "Worker pool created successfully") {
            toast.success(
              `You have successfully signup in workerpool as ${type}`,
              {
                position: toast.POSITION.BOTTOM_LEFT,
              }
            );
          }
          resolve(res?.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  AddEmployee(signUpData) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/worker/signup",
            null,
            signUpData
          );
          resolve(res?.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  seekerSignup(userData) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/seeker/signup",
            null,
            userData
          );
          const accessToken = res?.data?.token;
          setSession(accessToken);
          if (res?.data?.message === "Worker pool created successfully") {
            toast.success("You have successfully signup in workerpool", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          resolve(res?.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  sendOtp(token, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/send-otp",
            {},
            data,
            token
          );
          resolve(res?.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  verifyOtp(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/verify-otp",
            {},
            data,
            null
          );
          resolve(res?.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export const authApi = new AuthApi();
