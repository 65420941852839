import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./modalBox.scss";
const DeleteAccountModel = ({
  deleteAccount,
  openDeleteModel,
  setOpenDeleteModel,
}) => {
  return (
    <>
      <Modal
        isOpen={openDeleteModel}
        size="lg"
        centered
        className="profilePermission"
        backdrop={true}
      >
        <ModalBody className="p-3">
          <div className="modal-header d-flex flex-column  p-lg-5 p-md-4 p-3  text-white">
            <h3 className="text-center Modal-heading">Delete Account?</h3>
            <p className="text-center mt-3">
              Your WorkerPool profile will be permanently deleted. If you signed
              in with your NobelPage account, your NobelPage account won’t be
              affected.
            </p>
            <p className="text-center mt-3 text-danger">
              ( Handling) will be able to recover it within 90 days? immediately
              deleted? Account will be deleted.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-wrap justify-content-center align-items-center gap-3 my-4">
              <div
                className="noble-login-btns px-5 py-2 m-0"
                onClick={deleteAccount}
              >
                Continue
              </div>
              <div
                className="noble-login-btns px-5 py-2 m-0"
                onClick={() => setOpenDeleteModel(!openDeleteModel)}
              >
                Cancel
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteAccountModel;
