import React, { useEffect, useState } from "react";
import { FormGroup, Input, Modal, ModalBody, ModalHeader, NavLink } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./modalBox.scss";

const DeactiveAccountModel = ({ deactivateAccount, openDeactivateModel, setOpenDeactivateModel }) => {
  return (
    <>
      

      <Modal isOpen={openDeactivateModel} size="lg"  centered className="profilePermission" backdrop={true} >
        <ModalBody className="p-3">
          <div className="modal-header  d-flex flex-column  p-lg-5 p-md-4 p-3 text-white">
            <h3 className='text-center Modal-heading'>Deactivate Account?</h3>
            <p className='text-center mt-3' style={{maxWidth:"460px"}}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. putate
              libero et velit interdum, ac aliquet odio mattis.{" "}
            </p>
          </div>

          <div className='d-flex flex-column align-items-center'>
            <div className='d-flex flex-wrap justify-content-center align-items-center gap-3 my-4'>
              <div
                className="noble-login-btns px-5 py-2 m-0"
                onClick={deactivateAccount}
              >
                Continue
              </div>
              <div
                className="noble-login-btns px-5 py-2 m-0"
                onClick={() => setOpenDeactivateModel(!openDeactivateModel)}
              >
                Cancel
              </div>
            </div>       
          </div>
          
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeactiveAccountModel;
