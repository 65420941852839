import React, { useEffect, useState } from "react";
import "../JobPaymentModel/JobPaymentModel.scss";
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import HeaderLogo from "../Header/header";
import "./AccountVerifySuccess.scss";

const AccountVerifySuccessModel = ({ setPageNo }) => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderLogo />

      <Modal isOpen={true} size="lg" centered className="profileAccountPermission">
        <ModalBody className="p-3">
          <div className="modal-header d-flex flex-column  p-lg-5 p-md-4 p-3 py-4  text-white">
            <h3 className="text-center Modal-heading">Account Verified</h3>
            <p className="text-center mt-3">
              Thank you! Your account has been verified. Enjoy a great
              experience with NobelPage WorkerPool!
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="noble-login-btns px-5 py-2"
                onClick={() => setPageNo(3)}
              >
                Continue
              </div>
            </div>
          </div>
        </ModalBody>

        {/* <ModalBody>

        </ModalBody> */}
      </Modal>
    </>
  );
};

export default AccountVerifySuccessModel;
