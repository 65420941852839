import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { loadUserWTLogin, register } from "../../../store/auth/authActions";
import { call } from "../../../api/apiCall";
import { Navigate, useLocation } from "react-router-dom";

import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import BasicInformation from "./BasicInformation";
import { loadUser } from "../../../store/auth/authActions";
import {
  getAllCountries,
  getAllProfession,
  getAllReligion,
  getEmploymentTypes,
  getExperienceRegion,
} from "../../../store/dashboard/dashboardAction";

import Education from "../Education/Education";
import WorkExperience from "../WorkExperience/WorkExperience";
import Licence from "../Licence/Licence";
import Languages from "../Languages/Languages";
import { toast } from "react-toastify";
import { authApi } from "../../../api/authAPI";

export default function BasicInformationContainer({
  route,
  agency,
  setActivePage,
}) {
  const { user } = useSelector((state) => state.auth);
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [emailName, setEmailName] = useState("");
  // const [password, setPassword] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState(user?.phone ? user?.phone : "");
  const [errorEmail, setErrorEmail] = useState("");
  const [emailexistence, setEmailExistence] = useState(true);
  const [errorPassword, setErrorPassword] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorAbout, setErrorAbout] = useState("");
  const [errorHeadline, setErrorHeadline] = useState("");
  const [errorDob, setErrorDob] = useState("");
  const [errorExp, setErrorExp] = useState("");
  const [errorPrefCountry, setErrorPrefCountry] = useState("");
  const [errorNationality, setErrorNationality] = useState("");
  const [errorCategory, setErrorCategory] = useState("");
  const [errorProfession, setErrorProfession] = useState("");

  const [errorROE, setErrorROE] = useState("");
  const [errorJobType, setErrorJobType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorWhatsappNumber, setErrorWhatsappNumber] = useState("");
  const [sameAsPhone, setSameAsPhone] = useState(false);
  const [countinue, setCountinue] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [number, setNumber] = useState(1);
  const [errorGender, setErrorGender] = useState("");
  const [phoneCount, setPhoneCount] = useState("");
  const [whatsappCount, setWhatsappCount] = useState("");

  const phoneUtil = PhoneNumberUtil.getInstance();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // const  company_id = location?.state ? parseInt(location?.state?.id) : null;

  const {
    experienceRegion,
    religionList,
    employmentTypes: employmentTypeList,
    professionList,
    catergoryList,
    countryList,
  } = useSelector((state) => state.dashboard);
  const { isPageLoading } = useSelector((state) => state.loader);
  const [askNobelModal, setAskNobelModal] = useState(false);
  let [token, setToken] = useState("");
  let [workerId, setWorkerId] = useState(null);
  const getUser = () => {
    dispatch(loadUserWTLogin());
  };
  const [basicForm, setBasicForm] = useState(
    (location?.state?.fromNP &&
      (location?.state?.allowNp
        ? {
            first_name: user?.first_name,
            last_name: user?.last_name,
            about: user?.about,
            profile_headline: user?.profile_headline,
            email: user?.email,
            password: "",
            profession: [],
            dob: user?.dob ? new Date(user?.dob) : "",
            experience: 0,
            phone: user?.phone,
            whatsapp: "",
            desire_country: "",
            nationality: "",
            experience_region: "",
            work_type: "",
            religion: "",
            gender: "",
            agency_id: agency || null,
          }
        : {
            first_name: "",
            last_name: "",
            about: "",
            profile_headline: "",
            email: user?.email,
            password: "",
            dob: "",
            profession: [],
            experience: 0,
            phone: "",
            whatsapp: "",
            desire_country: "",
            nationality: "",
            experience_region: "",
            work_type: "",
            religion: "",
            gender: "",
            agency_id: agency || null,
          })) || {
      first_name: "",
      last_name: "",
      about: "",
      profile_headline: "",
      email: "",
      password: "",
      dob: "",
      profession: [],
      experience: 0,
      phone: "",
      whatsapp: "",
      desire_country: "",
      nationality: "",
      experience_region: "",
      work_type: "",
      religion: "",
      gender: "",
      agency_id: agency || null,
    }
  );

  // console.log("basicForm", location?.state?.fromNP, basicForm);
  // console.log("user", user);

  var today = new Date();
  useEffect(() => {
    if (location?.state?.fromNP) {
      getUser();
    }
    getRegionOfExperience();
    getNationality();
    getReligion();
    getEmploymentType();
    getCategory();
  }, []);
  useEffect(() => {
    getProfession();
  }, [selectedCategory]);

  // useEffect(() => {
  //   if (sameAsPhone) {
  //     setBasicForm({ ...basicForm, whatsapp: basicForm.phone });
  //   } else if (!sameAsPhone) {
  //     setBasicForm({ ...basicForm, whatsapp: "" });
  //   }
  // }, [sameAsPhone]);

  // useEffect(() => {
  //   if (basicForm?.whatsapp !== basicForm?.phone) {
  //     setSameAsPhone(false);
  //   }
  // }, [basicForm?.phone]);

  const emailExist = (emailName) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/auth/email-exist/${emailName}`,
            null,
            null,
            null
          );
          resolve(true);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  // const phoneExist = (phoneNumber) => {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await call(
  //           "get",
  //           `api/v1/auth/phone-exist/${phoneNumber}`,
  //           null,
  //           null,
  //           null
  //         );
  //         resolve(true);
  //       } catch (err) {
  //         resolve(false);
  //       }
  //     })();
  //   });
  // };

  // const [emailexistence, setEmailExistence] = useState(true);
  // const [phoneExistence, setPhoneExistence] = useState(true);

  const checkSignUpValidation = (type) => {
    let emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let stringName = /^[a-zA-Z._]+$/;
    let stringNameLast = /^[a-zA-Z._]+$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    let formIsValid = true;

    if (type === "all" || type === "firstName") {
      if (!basicForm?.first_name.trim()) {
        setErrorFirstName("Enter your first name ");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else if (!stringName.test(basicForm?.first_name.trim())) {
        setErrorFirstName("First name should be characters only.");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else {
        setErrorFirstName("");
      }
    }
    if (type === "all" || type === "lastName") {
      // if (!basicForm.last_name) {
      //   setErrorLastName("Enter your last name.");
      //   formIsValid = false;
      //   return;
      // } else
      if (!basicForm?.last_name.trim()) {
        setErrorLastName("Enter your last name ");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else if (!stringNameLast.test(basicForm.last_name.trim())) {
        setErrorLastName("Last name should be characters only.");
        // window.scrollTo(200,200)
        formIsValid = false;
        return;
      } else {
        setErrorLastName("");
      }
    }
    if (type === "all" || type === "about") {
      if (!basicForm?.about.trim()) {
        setErrorAbout("Please add about yourself ");
        // window.scrollTo(230,230)
        formIsValid = false;
        return;
      } else {
        setErrorAbout("");
      }
    }
    if (type === "all" || type === "headline") {
      if (!basicForm?.profile_headline.trim()) {
        setErrorHeadline("Please add headline ");
        // window.scrollTo(340,340)
        formIsValid = false;
        return;
      } else if (/[^a-zA-Z0-9\s]/.test(basicForm?.profile_headline)) {
        setErrorHeadline("Special characters are not allowed in the headline.");
        // window.scrollTo(340, 340);
        formIsValid = false;
        return;
      } else {
        setErrorHeadline("");
        formIsValid = true;
      }
    }

    if (type === "all" || type === "gender") {
      if (!basicForm?.gender) {
        setErrorGender("Please add gender ");
        formIsValid = false;
        return;
      } else {
        setErrorGender("");
        formIsValid = true;
      }
    }

    if (type === "all" || type === "dob") {
      if (!basicForm?.dob) {
        setErrorDob("Please add DOB");
        formIsValid = false;
        return;
      } else {
        setErrorDob("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "exp") {
      if (basicForm?.experience < 0) {
        setErrorExp("Please add experience ");
        formIsValid = false;
        return;
      } else if (
        new Date().getFullYear() -
          new Date(basicForm?.dob).getFullYear() -
          17 <=
        basicForm?.experience
      ) {
        setErrorExp("Invalid experience as based on date of birth");
        formIsValid = false;
        return;
      } else {
        setErrorExp("");
        formIsValid = true;
      }
    }

    if (type === "all" || type === "emailName") {
      if (!basicForm.email) {
        setErrorEmail("Please enter your email ");
        // window.scrollTo(370, 370);
        formIsValid = false;
        return;
      } else if (!emailValid.test(basicForm.email)) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
        return;
      } else if (basicForm.email && !location?.state?.fromNP) {
        emailExist(basicForm.email).then((response) => {
          if (response === false) {
            if (response.message === "Already exist") {
              setErrorEmail(
                "Email already exists on Nobel Page, Please continue as Nobel Page"
              );
              setEmailExistence(false);
              formIsValid = false;
            }
            return;
          } else {
            setErrorEmail("");
            setEmailExistence(true);
            formIsValid = true;
          }
        });
      } else {
        setErrorEmail("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "password") {
      if (!basicForm?.password) {
        setErrorPassword("Please enter your password ");
        formIsValid = false;
        return;
      } else if (!passwordValid.test(basicForm?.password)) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
        return;
      } else {
        setErrorPassword("");
      }
    }
    if (type === "all" || type === "phoneNumber") {
      if (basicForm?.phone?.length == 0) {
        setErrorPhoneNumber("Please enter the phone number ");
        formIsValid = false;
        return;
      } else if (basicForm?.phone?.length < 10) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      } else if (!phoneCountValidation(basicForm.phone, phoneCount)) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      }
      // else if (basicForm.phone) {
      //   phoneExist(basicForm.phone).then((response) => {
      //     if (response === false) {
      //       setErrorPhoneNumber("Phone already exist.");
      //       formIsValid = false;
      //       setPhoneExistence(false);
      //     } else {
      //       setErrorPhoneNumber("");
      //       setPhoneExistence(true);
      //     }
      //   });
      // }
      else {
        setErrorPhoneNumber("");
        formIsValid = true;
      }
    }

    if (type === "all" || type === "whatsappNumber") {
      if (basicForm.whatsapp.length === 0) {
        setErrorWhatsappNumber("Please enter the phone number ");
        formIsValid = false;
        return;
      } else if (basicForm.whatsapp.length < 10) {
        setErrorWhatsappNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      } else if (!phoneCountValidation(basicForm.whatsapp, whatsappCount)) {
        setErrorWhatsappNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      }
      // else if (phoneNumber) {
      //   phoneExist(phoneNumber).then((response) => {
      //     if (response === false) {
      //       setErrorPhoneNumber("Phone already exist.");
      //       formIsValid = false;
      //       setPhoneExistence(false);
      //     } else {
      //       setErrorPhoneNumber("");
      //       setPhoneExistence(true);
      //     }
      //   });
      // }
      else {
        setErrorWhatsappNumber("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "category") {
      if (!selectedCategory) {
        setErrorCategory("Please add category ");
        formIsValid = false;
        return;
      } else {
        setErrorCategory("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "profession") {
      if (!basicForm?.profession) {
        setErrorProfession("Please add professions for this category ");
        formIsValid = false;
        return;
      } else if (basicForm?.profession?.length > 5) {
        setErrorProfession("You can select upto 5 professions only");
        formIsValid = false;
        return;
      } else {
        setErrorProfession("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "prefCountry") {
      if (!basicForm?.desire_country) {
        setErrorPrefCountry("Please add preferred country ");
        formIsValid = false;
        return;
      } else {
        setErrorPrefCountry("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "nationality") {
      if (!basicForm?.nationality) {
        setErrorNationality("Please add nationality ");
        formIsValid = false;
        return;
      } else {
        setErrorNationality("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "roe") {
      if (!basicForm?.experience_region) {
        setErrorROE("Please add region of experience ");
        formIsValid = false;
        return;
      } else {
        setErrorROE("");
        formIsValid = true;
      }
    }

    if (type === "all" || type === "jobType") {
      if (!basicForm?.work_type) {
        setErrorJobType("Please add preferred job-type ");
        formIsValid = false;
        return;
      } else {
        setErrorJobType("");
        formIsValid = true;
      }
    }

    console.log(formIsValid);

    return formIsValid;
  };

  const phoneCountValidation = (mobileNumber, count) => {
    console.log(mobileNumber);
    let num = JSON.stringify(mobileNumber);
    let code = JSON.stringify(count);
    const number = phoneUtil?.parse(num, code);

    //console.log(phoneUtil.isValidNumber(number));
    return phoneUtil?.isValidNumber(number);
  };

  // const handleProfession = (e) => {
  //   let name = e.map((ele) => ele?.value);
  //   setBasicForm({
  //     ...basicForm,
  //     profession: [...name],
  //   });
  //   setErrorProfession("");
  // };
  const handleProfession = (e) => {
    let newProfessions = e.map((ele) => ele?.value);

    if (newProfessions.length > 5) {
      setErrorProfession("You can only add up to 5 professions.");
      return;
    }

    setBasicForm({
      ...basicForm,
      profession: [...newProfessions],
    });

    setErrorProfession("");
  };

  useEffect(() => {
    // console.log("basicForm.profession", basicForm.profession);
  }, [basicForm.profession]);

  const handleFormSubmit = () => {
    if (agency) {
      authApi
        .AddEmployee({ ...basicForm, created_by: "agency" })
        .then((res) => {
          if (res.success) {
            setToken(res?.token);
            setWorkerId(res?.data?.id);
            setCountinue(1);
          }
        })
        .catch((e) => {
          console.log(e);
          let message =
            (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString();
          toast.error(message, { position: toast.POSITION.BOTTOM_LEFT });
        });
    } else {
      dispatch(register(basicForm))
        .then((res) => {
          console.log("signup", res);
          if (res?.success) {
            setCountinue(1);
          }
        })
        .catch((e) => {
          let message =
            (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString();
          toast.error(message, { position: toast.POSITION.BOTTOM_LEFT });
          return;
        });
    }
  };

  const submitSignUpData = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (emailexistence) {
      handleFormSubmit();
    }
  };
  const getProfession = () => {
    dispatch(
      getAllProfession({ category: selectedCategory ? selectedCategory : null })
    );
  };

  const { isLoggedIn } = useSelector((state) => state.auth);

  //   if (isLoggedIn) {
  //     return location?.state?.from?.pathname === "/signup" ? (
  //       <Navigate to="/dashboard" />
  //     ) : (
  //       <Navigate
  //         to={location?.state?.from?.pathname}
  //         state={{ from: location }}
  //       />
  //     );
  //   }

  const getRegionOfExperience = () => {
    dispatch(getExperienceRegion());
  };

  const getNationality = () => {
    dispatch(getAllCountries());
  };
  const getReligion = () => {
    dispatch(getAllReligion());
  };
  const getEmploymentType = () => {
    dispatch(getEmploymentTypes());
  };
  const getCategory = () => {
    dispatch(getAllProfession());
  };
  if (countinue == 1) {
    return (
      <Education
        user={user}
        workerId={workerId}
        setCountinue={setCountinue}
        isLoggedIn={isLoggedIn}
        token={token}
        setActivePage={setActivePage}
      />
    );
  }
  if (countinue == 2) {
    return (
      <WorkExperience
        user={user}
        workerId={workerId}
        setCountinue={setCountinue}
        errorROE={errorROE}
        setErrorROE={setErrorROE}
        experienceRegion={experienceRegion}
        catergoryList={catergoryList}
        professionList={professionList}
        handleProfession={handleProfession}
        errorProfession={errorProfession}
        errorCategory={errorCategory}
        setErrorCategory={setErrorCategory}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        token={token}
      />
    );
  }
  if (countinue == 3) {
    return (
      <Licence
        user={user}
        workerId={workerId}
        setCountinue={setCountinue}
        token={token}
      />
    );
  }
  if (countinue == 4) {
    return (
      <Languages
        user={user}
        workerId={workerId}
        setCountinue={setCountinue}
        token={token}
        setActivePage={setActivePage}
      />
    );
  }
  return (
    <BasicInformation
      submitSignUpData={submitSignUpData}
      user={user}
      handleFormSubmit={handleFormSubmit}
      checkSignUpValidation={checkSignUpValidation}
      errorEmail={errorEmail}
      errorPassword={errorPassword}
      setErrorPassword={setErrorPassword}
      setErrorEmail={setErrorEmail}
      errorFirstName={errorFirstName}
      setErrorFirstName={setErrorFirstName}
      errorLastName={errorLastName}
      setErrorLastName={setErrorLastName}
      errorPhoneNumber={errorPhoneNumber}
      errorMessage={errorMessage}
      setPhoneCount={setPhoneCount}
      basicForm={basicForm}
      setBasicForm={setBasicForm}
      location={location}
      navigate={navigate}
      today={today}
      countryList={countryList}
      religionList={religionList}
      employmentTypeList={employmentTypeList}
      experienceRegion={experienceRegion}
      errorWhatsappNumber={errorWhatsappNumber}
      errorAbout={errorAbout}
      setErrorAbout={setErrorAbout}
      errorHeadline={errorHeadline}
      setErrorHeadline={setErrorHeadline}
      errorDob={errorDob}
      setErrorDob={setErrorDob}
      errorExp={errorExp}
      setErrorExp={setErrorExp}
      errorPrefCountry={errorPrefCountry}
      setErrorPrefCountry={setErrorPrefCountry}
      errorNationality={errorNationality}
      setErrorNationality={setErrorNationality}
      errorROE={errorROE}
      setErrorROE={setErrorROE}
      errorJobType={errorJobType}
      setErrorJobType={setErrorJobType}
      getRegionOfExperience={getRegionOfExperience}
      setCountinue={setCountinue}
      handleProfession={handleProfession}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      categoryArray={categoryArray}
      setCategoryArray={setCategoryArray}
      catergoryList={catergoryList}
      professionList={professionList}
      errorProfession={errorProfession}
      setErrorProfession={setErrorProfession}
      errorCategory={errorCategory}
      setErrorCategory={setErrorCategory}
      isPageLoading={isPageLoading}
      askNobelModal={askNobelModal}
      setAskNobelModal={setAskNobelModal}
      number={number}
      setNumber={setNumber}
      sameAsPhone={sameAsPhone}
      setSameAsPhone={setSameAsPhone}
      errorGender={errorGender}
      setErrorGender={setErrorGender}
      agency={agency}
      setActivePage={setActivePage}
      setWhatsappCount={setWhatsappCount}
      whatsappCount={whatsappCount}
    />
  );
}
