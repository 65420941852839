import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../dashboard/WorkerDashboard.scss";
import userImg from "../../../assets/img/user-img.png";
import callImg from "../../../assets/img/call-icon-blue.png";
import mailIcon from "../../../assets/img/mail-blue.png";
import whatsappIcon from "../../../assets/img/whatsapp.svg";
import videoImg from "../../../assets/img/video-img.png";
import watermark from "../../../assets/img/Watermark1.png";
import play_video from "../../../assets/img/play_video_icon.svg";
import play_video_icon from "../../../assets/img/video_play.svg";
import pdfImg from "../../../assets/img/google-docs.png";
import rightArrow from "../../../assets/img/Right.png";
import downloadImg from "../../../assets/img/download-gray.png";
import logoIconImg from "../../../assets/img/logoIcon.png";
import Header from "../../../core/headers/Header";
import Footer from "../../../core/footers/footer";
import placeholderUser from "../../../assets/img/userDefaultImage.svg";
import moment from "moment";
import LoginModal from "../../login/component/LoginModal";
import AddIntroVideo from "../../WorkerPoolProfilePages/Componenets/AddVideoModal";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
import backArrow from "../../../assets/img/backArrow.svg";
import whiteCallIcon from "../../../assets/img/whiteCallIcon.png";
import whiteWhatsappIcon from "../../../assets/img/whiteWhatsappIcon.png";
import whiteMailIcon from "../../../assets/img/whiteMailIcon.png";
import NobelJobIcon from "../../../assets/img/noble-job.svg";
import "./WorkerProfilePage.scss";
const WorkerProfilePage = (props) => {
  const {
    workerProfileData,
    isSeeMore,
    toggleSeeMore,
    phoneNum,
    mailTo,
    whatsAppTo,
    age,
    loginPopUp,
    setLoginPopUp,
    isLoggedIn,
    isPageLoading,
    addIntroModal,
    setAddIntroModal,
    videoUrl,
    nobelprofileData,
  } = props;

  const handleLink = (link, type) => {
    const alr = window.confirm(`Do you want to continue with ${type}`);
    if (alr) window.open(link, "_blank");
  };

  const navigate = useNavigate();

  return (
    <>
      {isPageLoading && <AppLoader />}
      <Header
        isProfilePage={true}
        user={nobelprofileData?.user ? nobelprofileData?.user : null}
      />

      <section className="profileInfo" style={{ padding: 0 }}>
        <div className="container-big">
          <div className="workerpool-default-form-page mb-3">
            <div className="back-btn" onClick={() => navigate(-1)}>
              <img src={backArrow} alt="Icon" />
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-xl-3 col-md-5 col-12 order-1 order-xl-0">
              <div className="userShortDetail">
                <div className="userImg">
                  <img
                    src={
                      workerProfileData?.user?.profile_img
                        ? workerProfileData?.user?.profile_img
                        : placeholderUser
                    }
                    className="user-profile-img"
                    alt="user-img"
                  />
                </div>
                <ul className="userKeyList">
                  {workerProfileData?.user?.experiences?.length > 0 && (
                    <li>
                      <h6>
                        <span>Work Experience</span>
                      </h6>
                      {workerProfileData?.user?.experiences.map(
                        (item, index) => (
                          <div className="userWorkDetail" key={index}>
                            <h3 className="title mx-0">{item?.title}</h3>
                            <h5 className="workPlace">{item?.company_name}</h5>
                            <p>{item?.location}</p>
                            <p className="workTimeLine">
                              <span className="startDate">
                                {moment(item?.start_date)
                                  ?.utc()
                                  ?.format("MMM YYYY")}
                              </span>{" "}
                              -{" "}
                              {
                                <span className="endDate">
                                  {item?.is_current
                                    ? "Present"
                                    : item?.end_date
                                    ? moment(item?.end_date)
                                        ?.utc()
                                        ?.format("MMM YYYY")
                                    : "No Date"}
                                </span>
                              }
                            </p>
                          </div>
                        )
                      )}
                    </li>
                  )}
                  {workerProfileData?.user?.educations?.length > 0 && (
                    <li>
                      <h6>
                        <span>Education</span>
                      </h6>
                      {workerProfileData?.user?.educations?.map(
                        (item, index) => (
                          <div className="userWorkDetail" key={index}>
                            <h3 className="title mx-0">{item?.degree}</h3>
                            <h5 className="workPlace">{item?.school_name}</h5>
                            <p className="courseType">{item?.field_of_study}</p>
                          </div>
                        )
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-7 col-12 order-2 order-xl-1">
              <div className="userFullDetail">
                <div className="aboutUser heightAdjust">
                  <div className="userdetail">
                    <div className="d-flex flex-wrap row-gap-2 justify-content-between mb-2 mb-md-0">
                      <h3
                        className="name card-control-text-oneline"
                        style={{ display: "-webkit-box" }}
                        title={
                          workerProfileData?.user?.first_name +
                          " " +
                          workerProfileData?.user?.last_name
                        }
                      >
                        {workerProfileData?.user?.first_name}{" "}
                        {workerProfileData?.user?.last_name}
                      </h3>
                      <div className="my-auto">
                        {workerProfileData?.is_public ? (
                          <span className="profileType public">
                            Public Mode
                          </span>
                        ) : (
                          <span className="profileType private">
                            Private Mode
                          </span>
                        )}
                      </div>
                    </div>
                    <h5 className="workfield">
                      {workerProfileData?.profession?.map((item, index) => (
                        <p style={{ marginTop: "8px", fontSize: "16px" }}>
                          ● {item?.profession}
                        </p>
                      ))}
                      {/* {workerProfileData?.profession
                        ?.map((item, index) => item?.profession)
                        ?.join(", ")} */}
                    </h5>
                    {workerProfileData?.Agency && (
                      <div
                        className="userKeyList agency mt-5"
                        style={{ padding: "0" }}
                      >
                        <h6>
                          <span>About Worker</span>
                        </h6>
                      </div>
                    )}
                    <div className="about">
                      <p>
                        {isSeeMore == true
                          ? workerProfileData?.user?.profile?.about?.slice(
                              0,
                              150
                            )
                          : workerProfileData?.user?.profile?.about}
                        {workerProfileData?.user?.profile?.about?.length >
                        150 ? (
                          <span className="see" onClick={toggleSeeMore}>
                            {isSeeMore == true ? "...See more" : " See less"}
                          </span>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                    {!workerProfileData?.Agency && (
                      <ul className="contactInfo d-flex gap-3">
                        <li>
                          <div>
                            {isLoggedIn ? (
                              <Link to={phoneNum}>
                                <div className="btn btn-light-blue">
                                  <img src={callImg} alt="call" /> Call{" "}
                                </div>
                              </Link>
                            ) : (
                              <div
                                className="btn btn-light-blue"
                                onClick={() => setLoginPopUp(true)}
                              >
                                <img src={callImg} alt="Icon" /> Call
                              </div>
                            )}
                          </div>
                        </li>
                        <li>
                          <div>
                            {isLoggedIn ? (
                              <div onClick={() => handleLink(mailTo, "mail")}>
                                <div className="btn btn-light-blue">
                                  <img src={mailIcon} alt="call" /> Mail
                                </div>
                              </div>
                            ) : (
                              <div
                                className="btn btn-light-blue"
                                onClick={() => setLoginPopUp(true)}
                              >
                                <img src={mailIcon} alt="Icon" /> Mail
                              </div>
                            )}
                          </div>
                        </li>
                        <li>
                          <div>
                            {isLoggedIn ? (
                              // <a href={whatsAppTo}>
                              <div
                                className="btn  btn-light-blue btn-light-green"
                                onClick={() =>
                                  handleLink(whatsAppTo, "whatsapp")
                                }
                              >
                                <img src={whatsappIcon} alt="call" /> Message{" "}
                              </div>
                            ) : (
                              // </a>
                              <div
                                className="btn  btn-light-blue btn-light-green"
                                onClick={() => setLoginPopUp(true)}
                              >
                                <img src={whatsappIcon} alt="Icon" /> Message
                              </div>
                            )}
                          </div>
                        </li>
                        {/* <li>
                        {workerProfileData?.created_by !== "independent" && (
                          <Link
                            to="/"
                            className="btn  btn-light-blue btn-blue-gradient"
                          >
                            {" "}
                            <img src={logoIconImg} alt="logo" /> View Agency{" "}
                          </Link>
                        )}
                      </li> */}
                      </ul>
                    )}
                  </div>
                  <ul className="userKeyList">
                    <li>
                      <h6>
                        <span>Profile Info</span>
                      </h6>
                      <div className="userProfileInfo table-responsive">
                        <table className="table table-borderless">
                          {age && (
                            <tr>
                              <td>Age</td>
                              <td>:</td>
                              <td>
                                <strong>{age}</strong>
                              </td>
                            </tr>
                          )}

                          {workerProfileData?.user?.profile?.gender && (
                            <tr>
                              <td>Gender</td>
                              <td>:</td>
                              <td>
                                <strong>
                                  {workerProfileData?.user?.profile?.gender}
                                </strong>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>Experience</td>
                            <td>:</td>
                            <td>
                              {workerProfileData?.experience ? (
                                <strong>
                                  {workerProfileData?.experience}{" "}
                                  {workerProfileData?.experience > 1
                                    ? " years"
                                    : "year"}
                                </strong>
                              ) : (
                                <strong>Fresher</strong>
                              )}
                            </td>
                          </tr>
                          {workerProfileData?.user?.profile?.nationality
                            ?.name && (
                            <tr>
                              <td>Nationality</td>
                              <td>:</td>
                              <td>
                                <strong>
                                  {workerProfileData?.user?.profile?.nationality
                                    ?.name || "No Data"}{" "}
                                </strong>
                              </td>
                            </tr>
                          )}
                          {workerProfileData?.desire_country && (
                            <tr>
                              <td>Preferred Country</td>
                              <td>:</td>
                              <td>
                                <strong>
                                  {workerProfileData?.desire_country}{" "}
                                </strong>
                              </td>
                            </tr>
                          )}
                          {workerProfileData?.experienceRegion?.region && (
                            <tr>
                              <td>Region of Experience</td>
                              <td>:</td>
                              <td>
                                <strong>
                                  {workerProfileData?.experienceRegion?.region}{" "}
                                </strong>
                              </td>
                            </tr>
                          )}
                          {workerProfileData?.work_type && (
                            <tr>
                              <td>Preferred Job Type</td>
                              <td>:</td>
                              <td>
                                <strong>
                                  {workerProfileData?.work_type?.split("-")
                                    ?.length
                                    ? workerProfileData?.work_type
                                        ?.split("-")
                                        .join(" ")
                                    : workerProfileData?.work_type}
                                </strong>
                              </td>
                            </tr>
                          )}
                          {workerProfileData?.religion && (
                            <tr>
                              <td>Religion</td>
                              <td>:</td>
                              <td>
                                <strong>{workerProfileData?.religion} </strong>
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-12 col-12 order-0 order-xl-2">
              {workerProfileData?.Agency && (
                <div className="card customCard mb-4 p-4">
                  <div className="companyInfo">
                    <span
                      className="profileType private p-2"
                      style={{
                        background: "rgba(255, 68, 43, 0.10)",
                        borderRadius: "10px",
                        color: "rgba(255, 68, 43, 1)",
                      }}
                    >
                      Agency
                    </span>
                    <div className="three-column-worker-section p-0 mt-4">
                      <div className="profile-box-outer flex-column pb-0 align-items-start">
                        <div className="profile-box px-2">
                          <img
                            src={
                              workerProfileData?.Agency?.icon || placeholderUser
                            }
                            alt=""
                          />
                          <div className="profile-head">
                            <h3>{workerProfileData?.Agency?.name}</h3>
                          </div>
                        </div>

                        <div className="icon-box" >
                          {workerProfileData?.Agency?.phone && (
                            <Link
                              to={`tel:${workerProfileData?.Agency?.phone}`}
                              className="icon call-icon"
                            >
                              <img src={whiteCallIcon} alt="" />
                            </Link>
                          )}
                          {workerProfileData?.Agency?.email && (
                            <div
                              className="icon"
                              onClick={() =>
                                handleLink(
                                  `mailto:${workerProfileData?.Agency?.email}`,
                                  "mail"
                                )
                              }
                            >
                              <img src={whiteMailIcon} alt="" />
                            </div>
                          )}
                          {workerProfileData?.Agency?.whatsapp && (
                            <div
                              className="icon whatsapp"
                              onClick={() =>
                                handleLink(
                                  `https://api.whatsapp.com/send?phone=${
                                    workerProfileData?.Agency?.whatsapp.split(
                                      "-"
                                    )[1] || workerProfileData?.Agency?.whatsapp
                                  }`,
                                  "whatsapp"
                                )
                              }
                            >
                              <img src={whiteWhatsappIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="companyLink d-flex align-items-center mt-4 gap-1 mx-3">
                        <img src={NobelJobIcon} alt="" />
                        <Link
                          style={{ color: "rgba(38, 72, 161, 1)" }}
                          to={`${process.env.REACT_APP_WEB_STAGE_URL}/directory/companydetail/${workerProfileData?.agency_id}`}
                          target="_blank"
                        >
                          View Company Page
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {workerProfileData?.user?.profile?.intro_video && (
                <div className="card customCard mb-4 p-4">
                  <h3>Introduction Video</h3>
                  {!isLoggedIn ? (
                    <div
                      className="videoBx"
                      style={{ cursor: "pointer" }}
                      onClick={() => setLoginPopUp(true)}
                    >
                      <img
                        src={videoImg}
                        alt="video"
                        className="w-100"
                        style={{ position: "relative" }}
                      />
                      <img
                        src={play_video}
                        alt="video"
                        style={{
                          position: "absolute",
                          margin: "auto",
                          top: "55%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                      <img
                        src={play_video_icon}
                        alt="video"
                        style={{
                          position: "absolute",
                          margin: "auto",
                          top: "55%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="videoBx"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        background: "#000",
                      }}
                      // onClick={() => setAddIntroModal(!addIntroModal)}
                    >
                      <video
                        controls
                        width="100%"
                        height="150px"
                        disableRemotePlayback
                        disablePictureInPicture
                        controlsList="nodownload noplaybackrate"
                      >
                        <source
                          src={workerProfileData?.user?.profile?.intro_video}
                        />
                      </video>
                      <img
                        src={watermark}
                        width={50}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {workerProfileData?.user?.profile?.resume_url && (
                <div className="card customCard mb-4 p-4">
                  <h3>Resume</h3>
                  <div className="d-flex flex-row align-items-center">
                    <div className="w-75 d-flex flex-row gap-2">
                      <img src={pdfImg} alt="pdf-img" />
                      <div className="docBreef d-flex flex-column justify-content-center gap-1">
                        <h4>
                          {" "}
                          {workerProfileData?.user?.profile?.resume_name.slice(
                            0,
                            6
                          ) +
                            (workerProfileData?.user?.profile?.resume_name
                              .length > 6
                              ? "..."
                              : "")}
                        </h4>
                        <div className="d-flex flex-row gap-3">
                          {/* <span className="name">Pdf</span>
                          <span className="size">3mb</span> */}
                          {workerProfileData?.user?.profile?.resume_date && (
                            <span className="time">
                              updated{" "}
                              {moment(
                                workerProfileData?.user?.profile?.resume_date
                              )
                                ?.utc()
                                ?.format("MMM DD YYYY")}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-25 text-center">
                      <div
                        className="downloadBtn"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={downloadImg}
                          alt="download-img"
                          onClick={() =>
                            isLoggedIn
                              ? window.open(
                                  workerProfileData?.user?.profile?.resume_url
                                )
                              : setLoginPopUp(true)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="gradientCard">
                <h3>
                  Benefits of <span>NobelPage</span>
                </h3>
                <p>
                  Enjoy even more add-on packages, offering additional benefits
                  at discounted pricing. Your Professional Networking Platform,
                  where Skills Win over Bias!
                </p>
                <div className="actionGroup">
                  <div
                    className="btn btn-white"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/signup`
                      )
                    }
                  >
                    Join Now <img src={rightArrow} alt="right-arrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loginPopUp && (
          <LoginModal
            modal={loginPopUp}
            toggle={() => setLoginPopUp(!loginPopUp)}
            workerId={workerProfileData?.id}
            setLoginPopUp={setLoginPopUp}
          />
        )}
        {addIntroModal && (
          <AddIntroVideo
            modal={addIntroModal}
            toggle={() => setAddIntroModal(!addIntroModal)}
            videoUrl={videoUrl}
            isView={true}
          />
        )}
      </section>
      <Footer />
    </>
  );
};
export default WorkerProfilePage;
