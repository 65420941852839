import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import bottomIcon1 from "../../assets/img/bottomIcon-1.png";
import bottomIcon2 from "../../assets/img/bottomIcon-2.png";
import bottomIcon3 from "../../assets/img/bottomIcon-3.png";
import bottomIcon4 from "../../assets/img/bottomIcon-4.png";
import { useDispatch, useSelector } from "react-redux";
import { ApplyJob } from "../../store/job/jobAction";
import { ToastContainer, toast } from "react-toastify";
import { profileApi } from "../../api/profileApi";
import axios from "axios";

const FeatureJob = ({
  jobList,
  jobType,
  setJobType,
  searchAllJobs,
  searchFullTimeJob,
  searchPartTimeJob,
}) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createSearch = (type_id, search) => {
    profileApi.createSearchHistory({
      platform_type: "wp",
      type: "job",
      type_id,
      search,
    });
  };

  const applyJob = (id) => {
    if (!isLoggedIn) navigate("/login");
    dispatch(ApplyJob(id))
      .then((res) => {
        if (res.success) {
          toast.success(res?.message);
        } else {
          toast.error(res?.response?.data?.message);
        }
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  };

  const [currencyCodes, setCurrencyCodes] = useState({});

  const currencySymbol = async () => {
    try {
      const res = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/USD`
      );
      if (res?.status == 200) {
        setCurrencyCodes(res?.data?.rates);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    currencySymbol();
  }, []);

  const time = (date) => {
    const now = new Date();
    const createdAt = new Date(date);

    const timeDifference = now.getTime() - createdAt.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let timeAgo;

    if (daysDifference > 0) {
      timeAgo =
        daysDifference === 1 ? "1 day ago" : daysDifference + " days ago";
    } else if (hoursDifference > 0) {
      timeAgo =
        hoursDifference === 1 ? "1 hour ago" : hoursDifference + " hours ago";
    } else if (minutesDifference > 0) {
      timeAgo =
        minutesDifference === 1
          ? "1 minute ago"
          : minutesDifference + " minutes ago";
    } else {
      timeAgo = "Just Now";
    }
    return timeAgo;
  };

  return (
    <section className="two-column-worker-section">
      <div className="container-big">
        <div className="head">
          <div className="col">
            <div className="row justify-content-between">
              <div className="col-auto">
                <h2>Featured Jobs</h2>
                <p>Discover your worth and find a job that you desire.</p>
              </div>
              <div className="col-auto mt-lg-0 mt-3">
                <ul className="filterBtn d-flex flex-wrap gap-2">
                  <li>
                    <button
                      className={"btn " + (jobType === "" ? "active" : "")}
                      onClick={(e) => {
                        searchAllJobs(e);
                        setJobType("");
                      }}
                    >
                      All
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        "btn " + (jobType === "full-time" ? "active" : "")
                      }
                      onClick={(e) => {
                        searchFullTimeJob(e);
                        setJobType("full-time");
                      }}
                    >
                      Full time
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        "btn " + (jobType === "part-time" ? "active" : "")
                      }
                      onClick={(e) => {
                        searchPartTimeJob(e);
                        setJobType("part-time");
                      }}
                    >
                      Part time
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {!jobList && !jobList?.jobList?.length && (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <p>No Jobs Found</p>
              </div>
            </>
          )}
          {jobList?.jobList?.slice(0, 6)?.map((ele) => {
            return (
              <>
                {console.log(ele, "elelelelelel")}
                <div className="col-md-6 col-12" keys={ele?.id}>
                  <div className="col-outer flex-column">
                    <div className="d-flex justify-content-between">
                      <div className="left">
                        {ele?.title?.length > 50 ? (
                          <>
                            <h3
                              className="card-control-text-oneline"
                              onClick={() => {
                                createSearch(ele?.id, ele?.title);
                                navigate(`/job/${ele?.id}`);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {ele?.title?.slice(0, 50)}...
                            </h3>
                          </>
                        ) : (
                          <>
                            <h3
                              className="card-control-text-oneline"
                              onClick={() => {
                                createSearch(ele?.id, ele?.title);
                                navigate(`/job/${ele?.id}`);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {ele?.title}
                            </h3>
                          </>
                        )}
                        {ele?.company_name?.length > 50 ? (
                          <span className="detail .card-control-text-oneline">
                            {ele?.company_name?.slice(0, 50)}...
                          </span>
                        ) : (
                          <span className="detail .card-control-text-oneline">
                            {ele?.company_name}
                          </span>
                        )}
                      </div>
                      <div className="right">
                        {user?.worker?.type !== "provider" && (
                          <button
                            className={
                              ele?.user_id !== user?.id
                                ? "apply-btn border-0"
                                : "d-none border-0"
                            }
                            onClick={() => applyJob(ele?.id)}
                            disabled={
                              ele?.appliedJob &&
                              ele?.appliedJob?.apply_status != "rejected"
                            }
                          >
                            {ele?.appliedJob &&
                            ele?.appliedJob?.apply_status != "rejected"
                              ? "Applied"
                              : "Apply Job"}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="bottom-text">
                      <span>
                        <img src={bottomIcon1} alt="Icon" />{" "}
                        {ele?.job_location || "location"}
                      </span>
                      <span>
                        <img src={bottomIcon2} alt="Icon" />{" "}
                        {time(ele?.createdAt)}
                      </span>
                      <span>
                        <img src={bottomIcon3} alt="Icon" />{" "}
                        {ele?.min_experience} - {ele?.max_experience}{" "}
                        {ele?.max_experience <= 1 ? "year" : "years"}
                      </span>
                      <span>
                        <img src={bottomIcon4} alt="Icon" />{" "}
                        {Math.round(
                          ele?.min_salary *
                            currencyCodes[ele?.salary_type || "USD"]
                        ) >= 1000
                          ? Math.round(
                              (ele?.min_salary *
                                currencyCodes[ele?.salary_type || "USD"]) /
                                1000
                            ) + "K"
                          : Math.round(
                              ele?.min_salary *
                                currencyCodes[ele?.salary_type || "USD"]
                            )}{" "}
                        -{" "}
                        {Math.round(
                          ele?.max_salary *
                            currencyCodes[ele?.salary_type || "USD"]
                        ) >= 1000
                          ? Math.round(
                              (ele?.max_salary *
                                currencyCodes[ele?.salary_type || "USD"]) /
                                1000
                            ) + "K"
                          : Math.round(
                              ele?.max_salary *
                                currencyCodes[ele?.salary_type || "USD"]
                            )}{" "}
                        {ele?.salary_type || "USD"}
                      </span>
                      <span className="red-clr">
                        {ele?.employment_type?.split("-")?.length
                          ? ele?.employment_type?.split("-").join(" ")
                          : ele?.employment_type}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {jobList?.totalCount > jobList?.jobList?.length && (
          <div className="view-more-btn-outer">
            <Link
              to="/jobs"
              state={{ job_type: jobType }}
              className="view-more-btn"
            >
              View more
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};
export default FeatureJob;
