import React from "react";

import workerDummy1 from "../../assets/img/workerIcon.svg";
import workerDummy2 from "../../assets/img/providerIcon.svg";
import workerDummy3 from "../../assets/img/seekerIcon.svg";

const WorkRequest = () => {
  return (
    <section className="three-column-box-section">
      <div className="container-big">
        <div className="head">
          <h3 className="text-center" style={{ fontSize: "32px" }}>
            How it work’s
          </h3>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 icon-col">
            <div className="col-outer" style={{ height: "290px" }}>
              <span className="icon">
                <img src={workerDummy1} alt="Icon" />
              </span>
              <h3>I am a Worker</h3>
              <p>
                If you're a worker, simply log with your to NobelPage or
                register for free, create a profile, and list it as an
                independent to be found and contacted at no additional cost.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 icon-col">
            <div className="col-outer" style={{ height: "290px" }}>
              <span className="icon">
                <img src={workerDummy2} alt="Icon" />
              </span>
              <h3>I Provide Workers</h3>
              <p>
                Staffing and outsourcing agencies, along with companies offering
                blue and pink-collar workers including nannies and more, can
                list their services here for free. Manage your listings to be
                contacted for free.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 icon-col">
            <div className="col-outer" style={{ height: "290px" }}>
              <span className="icon">
                <img src={workerDummy3} alt="Icon" />
              </span>
              <h3>I am looking for Workers</h3>
              <p>
                Whether you're on the hunt for temporary, part-time, or
                full-time workers as an individual, or your company is in need
                of diverse staff, look no further—you'll discover exactly what
                you need right here!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WorkRequest;
