import { toast } from "react-toastify";
import { dashboardApi } from "../../api/dashboardApi";
import { profileApi } from "../../api/profileApi";
import { upDateUserData } from "../auth/authActions";
import {
  initLoad,
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../loader/loaderActions";
import {
  REMOVE_EXPERIENCE,
  REMOVE_EDUCATION,
  REMOVE_LICENSE,
  GET_SEARCH_HISTORY,
  REMOVE_LANGUAGE,
  ADD_EDUCATION_DATA,
  ADD_EXPERIENCE_DATA,
  ADD_LANGUAGE_DATA,
  ADD_LICENSE_DATA,
  DELETE_INTRO_VIDEO,
  GET_DATA_BY_ID,
  GET_LOCATIONS_SUCCESS,
  GET_USER_DATA,
  GET_WORKER_PROFILE,
  GET_WP_DATA_BY_ID,
  PUBLISH_AS_PUBLIC,
  UPDATE_EDUCATION_DATA,
  UPDATE_EXPERIENCE_DATA,
  UPDATE_LANGUAGE_DATA,
  UPDATE_LICENSE_DATA,
  UPDATE_NOBEL_DATA,
  UPLOAD_INTRO_VIDEO,
  UPLOAD_RESUME_DATA,
  ADD_BUSINESS_PAGE,
  FAIL_GET_WORKER_LIST,
} from "../types";

export const getWorkerProfile = (id) => (dispatch) => {
  dispatch(initPageLoad());
  return profileApi.getWorkerProfile(id).then(
    (response) => {
      dispatch({
        type: GET_WORKER_PROFILE,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getUserProfileById = (id, token) => (dispatch) => {
  dispatch(initPageLoad());
  return profileApi.getUserDetailsByID(id, token).then(
    (response) => {
      console.log("get id res", response);
      dispatch({
        type: GET_DATA_BY_ID,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getWPUserProfileById = (id, token) => (dispatch) => {
  dispatch(initPageLoad());
  return profileApi.getWPuserbyId(id, token).then(
    (response) => {
      dispatch({
        type: GET_WP_DATA_BY_ID,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const addeducationdetails = (userData, token) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      console.log(userData);
      if (userData.educations[0].id) {
        dispatch({
          type: UPDATE_EDUCATION_DATA,
          payload: response.data.education,
        });
      } else {
        dispatch({
          type: ADD_EDUCATION_DATA,
          payload: response.data.education,
        });
      }
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const getNobelUserProfile = (id, token) => (dispatch) => {
  // dispatch(initPageLoad());
  return profileApi.getNobelProfile(id, token).then(
    (response) => {
      dispatch({
        type: GET_USER_DATA,
        payload: response.data,
      });
      // dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const getLocationsData = (searchKey) => (dispatch) => {
  return dashboardApi.getLocations(searchKey).then(
    (data) => {
      // console.log(data);
      dispatch({
        type: GET_LOCATIONS_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
export const updateNobelUserDetails = (userData, token) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      dispatch({
        type: UPDATE_NOBEL_DATA,
        payload: userData,
      });
      dispatch(upDateUserData(userData));
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};
export const updateWorkerUserDetails = (userData, token, id) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postWorkerUserDetails(userData, token, id).then(
    (response) => {
      dispatch({
        type: UPDATE_NOBEL_DATA,
        payload: userData,
      });
      dispatch(upDateUserData(userData));
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const updateWorkerDetailsByAgency =
  (userData, token, id) => (dispatch) => {
    dispatch(initLoad());
    return profileApi.postWorkerUserDetails(userData, token, id).then(
      (response) => {
        // dispatch({
        //   type: UPDATE_NOBEL_DATA,
        //   payload: userData,
        // });
        //  dispatch(upDateUserData(userData));
        dispatch(stopLoad());
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };

export const addexperiencedetails = (userData, token) => (dispatch) => {
  let expDataformat = {
    experiences: [userData],
  };
  dispatch(initLoad());
  return profileApi.postUserDetails(expDataformat, token).then(
    (response) => {
      //       console.log(userData);
      if (userData.id) {
        dispatch({
          type: UPDATE_EXPERIENCE_DATA,
          payload: response.data.experience[0],
        });
      } else {
        dispatch({
          type: ADD_EXPERIENCE_DATA,
          payload: response.data.experience[0],
        });
      }
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const addlicensedetails = (userData, token) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.license_certifications[0].id) {
        dispatch({
          type: UPDATE_LICENSE_DATA,
          payload: response.data.licenseCertification,
        });
      } else {
        dispatch({
          type: ADD_LICENSE_DATA,
          payload: response.data.licenseCertification,
        });
      }
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const addlanguagedetails = (userData, token) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.languages[0].id) {
        dispatch({
          type: UPDATE_LANGUAGE_DATA,
          payload: userData.languages,
        });
      } else {
        dispatch({
          type: ADD_LANGUAGE_DATA,
          payload: response.data.language,
        });
      }
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const uploadVideo = (videoData, token) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postUserDetails(videoData, token).then(
    (response) => {
      dispatch({
        type: UPLOAD_INTRO_VIDEO,
        payload: videoData,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const deleteVideo = (videoData, token) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postUserDetails(videoData, token).then(
    (response) => {
      dispatch({
        type: DELETE_INTRO_VIDEO,
        payload: videoData,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const uploadResume = (resumeData, token) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.postUserDetails(resumeData, token).then(
    (response) => {
      dispatch({
        type: UPLOAD_RESUME_DATA,
        payload: resumeData,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const publishAsPublic = (token) => (dispatch) => {
  return profileApi.publicYouProfile(token).then(
    (response) => {
      dispatch({
        type: PUBLISH_AS_PUBLIC,
        payload: response,
      });
      console.log("publish res", response);
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.resolve(error);
    }
  );
};

export const AddCompany = (data) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.addPage(data).then(
    (response) => {
      console.log(response?.data);
      dispatch({
        type: ADD_BUSINESS_PAGE,
        payload: response?.data,
      });
      console.log("publish res", response);
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const removeProfileSubData = (type, id) => (dispatch) => {
  dispatch(initLoad());
  return profileApi.deleteProfileSubData(type, id).then(
    (response) => {
      switch (type) {
        case "experience":
          dispatch({
            type: REMOVE_EXPERIENCE,
            payload: id,
          });
        case "education":
          dispatch({
            type: REMOVE_EDUCATION,
            payload: id,
          });
        case "license-certification":
          dispatch({
            type: REMOVE_LICENSE,
            payload: id,
          });
        case "language":
          dispatch({
            type: REMOVE_LANGUAGE,
            payload: id,
          });
      }
      // dispatch({
      //   type: DELETE_INTRO_VIDEO,
      //   payload: videoData,
      // });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const getSearchHistory = (params) => (dispatch, getState) => {
  const { searchHistory } = getState().profile;
  dispatch(initPageLoad());
  return profileApi.getSearchHistory(params).then(
    (response) => {
      dispatch({
        type: GET_SEARCH_HISTORY,
        // payload: response
        payload:
          searchHistory && params?.skip && params?.limit
            ? [...response?.data, ...searchHistory]
            : response?.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error.response.data.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
