import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
} from "reactstrap";
import "./JobPaymentModel.scss";
import Card1 from "../../assets/img/creditcard1.svg";
import Card2 from "../../assets/img/creditcard2.svg";
import { useDispatch } from "react-redux";
import {
  deleteWorkers,
  makeProfilePublic,
} from "../../store/provider/ProviderAction";
import { toast } from "react-toastify";
import { jobApi } from "../../api/jobApi";

const JobPaymentModel = ({
  isOpen,
  setIsModelOpen,
  successModel,
  setSuccessModel,
  data,
  setExpData,
  currencyCodes,
}) => {
  //   const [selectWorker, setSelectWorker] = useState([]);
  //   useEffect(() => {
  //     const data = selectedWorker.map((el) => {
  //       return worker.find((ele) => ele.id == el);
  //     });
  //     setSelectWorker(data);
  //   }, []);

  const handleClick = (e) => {
    e.preventDefault();
    let min_salary = Number(
      (Number(data?.min_salary) / currencyCodes[data?.salary_type]).toFixed(0)
    );
    let max_salary = Number(
      (Number(data?.max_salary) / currencyCodes[data?.salary_type]).toFixed(0)
    );
    delete data?.min_salary;
    delete data?.max_salary;

    Object.assign(data, { min_salary, max_salary });
    jobApi
      .postJob(data)
      .then((res) => {
        if (res.success) {
          setSuccessModel(true);
          setIsModelOpen(!isOpen);
        }
      })
      .catch((err) =>
        alert(err?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="lg"
        centered
        className=""
        toggle={() => setIsModelOpen(!isOpen)}
        backdrop={true}
      >
        <ModalBody className=" p-lg-5 p-md-4 p-3 bg-transparent">
          <h2>Payment For Job Post</h2>

          <p>
            NobelPage members are eligible for a limited number of job posts.
            Additional 30-day posts incur a small fee.
          </p>
          <div className="mt-4">
            {/* <div className="horizental_line"></div> */}
            <FormGroup>
              <Input type="checkbox" style={{ border: "1px solid #000" }} />
              <strong className="mx-2">
                Use your free membership job posts (3/5)
              </strong>
            </FormGroup>
            <div
              className="d-flex justify-content-between mt-3 px-2 pe-2"
              style={{
                background: "#19BCF41A",
                padding: "5px",
                alignTtems: "center",
                borderRadius: "5px",
              }}
            >
              <p className="user_name" style={{ color: "#19BCF4" }}>
                Job Post Amount
              </p>
              <p className="price font-bold" style={{ color: "#19BCF4" }}>
                $00.00
              </p>
            </div>
          </div>

          <div className=" mt-4">
            <form>
              <div className="row">
                <div className="col-12">
                  <FormGroup>
                    <label htmlFor="card_number" className="form-label">Card Number</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_number"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <FormGroup>
                    <label htmlFor="card_name"  className="form-label">Name of Card</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_name"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <FormGroup>
                    <label htmlFor="card_validity"  className="form-label">Validity</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_validity"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
                <div className="col-6">
                  <FormGroup>
                    <label htmlFor="card_ccv"  className="form-label">CCV</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_ccv"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="">
                <Input type="checkbox" style={{ border: "1px solid #000" }} />{" "}
                Save this card
              </div>

              <div className="mt-4 saved_card">
                <div className="headline p-2">
                  <p className="mx-2">Saved Cards</p>
                </div>
                <div className="d-flex justify-content-between mt-3 p-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <Input
                      type="radio"
                      name="saved_card"
                      style={{ border: "1px solid #000" }}
                    />
                    <div className="mx-2">
                      <p className="card_name">Ending in:...623</p>
                      <p className="card_expiry">Exp date: 12/26</p>
                    </div>
                  </div>
                  <div className="">
                    <img src={Card1} />
                  </div>
                </div>
                <div className="horizental_line"></div>
                <div className="d-flex justify-content-between mt-3 p-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <Input
                      type="radio"
                      name="saved_card"
                      style={{ border: "1px solid #000" }}
                    />
                    <div className="mx-2">
                      <p className="card_name">Ending in:...623</p>
                      <p className="card_expiry">Exp date: 12/26</p>
                    </div>
                  </div>
                  <div className="">
                    <img src={Card2} />
                  </div>
                </div>
              </div>
            </form>

            <div className="mt-3 d-flex justify-content-center">
              <button
                className="pay_btn px-4 py-2"
                onClick={(e) => handleClick(e)}
              >
                Pay Now
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default JobPaymentModel;
