import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/style.scss";
import Router from "./router/router";
import Wrapper from "./router/scrollToTop";
import NoInternetConnection from "./pages/error/noInternetError";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./store/auth/authActions";
import { ToastContainer } from "react-toastify";

function App() {
  const dispatch = useDispatch();
  // const [processing, setProcessing] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      dispatch(loadUser());
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <NoInternetConnection>
            <Router />
          </NoInternetConnection>
        </Wrapper>
      </BrowserRouter>

      <ToastContainer autoClose={1000} />
    </>
  );
}

export default App;
