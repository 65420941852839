import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVideo,
  updateWorkerUserDetails,
  uploadVideo,
} from "../../../store/profile/ProfileAction";
import upload from "../../../assets/img/upload-icon.svg";
import { toast } from "react-toastify";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";
import Loader from "../../../core/Loaders/ComponentLoader";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
// import WebcamVideo from "./recordVideo/recordVideo";
import waterMarkIcon from "../../../assets/img/Watermark1.png";
export default function AddIntroVideo(props) {
  const { modal, toggle, videoUrl, isView, workerId } = props;

  const dispatch = useDispatch();
  const [introVideo, setIntroVideo] = useState(videoUrl ? videoUrl : "");
  const [urlData, setUrlData] = useState();
  const { isLoading } = useSelector((state) => state.loader);
  const { nobelprofileData } = useSelector((state) => state.profile);
  const onChangeVideo = async (e) => {
    const file = e?.target?.files[0];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();

      // Check if the file extension is mp4
      if (fileExtension === "mp4") {
        console.log(file.size, "files size");

        if (file.size < 10 * 1024 * 1024) {
          setIntroVideo(URL.createObjectURL(file));

          let tempimage = [];
          let type = "video";
          Array.from(e?.target?.files).map((file) => {
            return tempimage.push({ type, file });
          });
          try {
            const UPLOAD_URL = `${"https://nobelpageapi.dev.vinove.com/"}api/v1/upload`;
            const formData = new FormData();
            const headers = {
              "Accept-Language": "en",
              "content-type": "multipart/form-data",
            };

            for (var i = 0; i < tempimage.length; i++) {
              formData.append("image", tempimage[i].file);
            }
            dispatch(initLoad());
            await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
              dispatch(stopLoad());
              console.log(res?.data?.urlsArray[0]);
              setUrlData(res?.data?.urlsArray[0]);
              // setCroppedImage(res?.data?.urlsArray[0]);
            });
          } catch (err) {
            dispatch(stopLoad());
            console.log(err);
          }
        } else {
          toast.error("File size exceeds 10MB. Please choose a smaller file.");
        }
      } else {
        toast.error("Only MP4 files are allowed.");
      }
    }
  };

  const deleteIntroVideo = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("accessToken");
    let data = {
      intro_video: null,
    };
    dispatch(initLoad());
    if (workerId)
      dispatch(updateWorkerUserDetails(data, token, nobelprofileData?.id)).then(
        (response) => {
          dispatch(stopLoad());
        }
      );
    else
      dispatch(deleteVideo(data, token)).then((response) => {
        dispatch(stopLoad());
      });
    setUrlData("");
    setIntroVideo("");
  };

  const handleSubmit = () => {
    let token = localStorage.getItem("accessToken");
    let data = {
      intro_video: urlData,
    };
    if (!urlData) {
      toast.error("Please upload video");
    } else {
      dispatch(initLoad());
      if (workerId)
        dispatch(
          updateWorkerUserDetails(data, token, nobelprofileData?.id)
        ).then((response) => {
          if (response.success) {
            toast.success("You have successfully uploaded intro video", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          toggle();
          dispatch(stopLoad());
        });
      else
        dispatch(uploadVideo(data, token)).then((response) => {
          console.log("video res", response);
          if (response.success) {
            toast.success("You have successfully uploaded intro video", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          toggle();
          dispatch(stopLoad());
        });
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <Modal
        isOpen={modal}
        size="md"
        toggle={toggle}
        style={{ maxWidth: "876px", borderRadius: "none" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="add-intro">
          <ModalHeader
            style={{ padding: "30px 30px 20px 30px", border: "none" }}
            toggle={toggle}
          >
            Introduction Video
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px 0px 30px" }}>
            <form>
              <div className="title">
                <label>
                  Upload or record a brief introduction video using your device
                  camera. Keep it under one minute for optimal engagement.
                </label>
              </div>
              <div
                // style={{height:"500px"}}
                className="my-4"
              >
                <input
                  type="file"
                  id="intro_video"
                  name="intro_video"
                  accept="video/mp4"
                  style={{ display: "none" }}
                  onChange={(e) => onChangeVideo(e)}
                  onClick={(e) => (e.target.value = "")}
                />
                {introVideo || isView ? (
                  <div
                    className={isView ? "mb-5" : ""}
                    style={{ position: "relative", background: "#000" }}
                  >
                    <video
                      controls
                      disableRemotePlayback
                      disablePictureInPicture
                      width="100%"
                      height="300px"
                      controlsList="nodownload nofullscreen noplaybackrate"
                    >
                      <source src={introVideo} />
                    </video>
                    <img
                      src={waterMarkIcon}
                      width={50}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </div>
                ) : (
                  // : tab === "recording" ? (
                  //   <WebcamVideo
                  //   setUrlData={setUrlData}
                  //   />
                  // )
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "30px",
                    }}
                  >
                    <label className="intro-video-inner" htmlFor="intro_video">
                      <img
                        src={upload}
                        alt="upload"
                        style={{ marginTop: "105px" }}
                      />
                      <div className="upload-icon">Upload Video</div>
                    </label>
                    {/* <label
                        style={{
                          display: "block",
                          cursor: "pointer",
                          fontSize: "15px",
                          background: "#e5e5e5",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        <div onClick={() => setTab("recording")}>
                          Record Video
                        </div>
                      </label> */}
                  </div>
                )}
              </div>
              {/* {!isView && (
                <p className="mt-2 fs-6">
                  * Allowed only file types mp4 files not more than 10MB
                </p>
              )} */}
              {!isView && introVideo && (
                <div className="add-media my-4">
                  <button
                    disabled={introVideo ? false : true}
                    className="btn btn-red-outline"
                    onClick={(e) => deleteIntroVideo(e)}
                    style={{ width: "161.5px" }}
                  >
                    Remove
                  </button>
                  {urlData && (
                    <div
                      className="btn btn-blue"
                      style={{ width: "161.5px" }}
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save
                    </div>
                  )}
                </div>
              )}
            </form>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
