import React from "react";
import "./footer.scss";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import workerpoolIcon from "../../assets/img/blueWorkerpoolIcon.svg";
const Footer = () => {
  return (
    <footer className="pageFooter">
      <div className="container-big">
        <hr />
        <div className="row">
          <div className="col-lg-5 order-1 order-lg-0 d-flex align-items-center">
            <img src={workerpoolIcon} />
            <div className="copyRight mx-5 text-secondary">
              NobelPage © {new Date().getFullYear()}{" "}
            </div>
          </div>
          <div className="col-lg-7 order-0 order-lg-1">
            <div className="footerLink">
              <Nav>
                <NavItem
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/about`)
                  }
                >
                  About
                </NavItem>
                <NavItem
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/legal`)
                  }
                >
                  Legal Terms
                </NavItem>
                <NavItem
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_WEB_STAGE_URL}/community`
                    )
                  }
                >
                  Community Guidelines
                </NavItem>
                <NavItem
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/help`)
                  }
                >
                  Help Center
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
