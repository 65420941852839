import React, { useEffect, useState } from "react";
import Sidebar from "../Componenets/Sidebar";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "../WorkerPoolProfile.scss";
import "../../WorkerPoolFormPages/WorkerPoolDefaultForm.scss";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../../../assets/img/backArrow.svg";
import mastercardSmall from "../../../assets/img/mastercardSmall.png";
import visacrdSmall from "../../../assets/img/visacrdSmall.png";
import deleteIcon from "../../../assets/img/deleteBlack.png";
import { call } from "../../../api/apiCall";
import "./MyJobs.scss";
import { useDispatch, useSelector } from "react-redux";
import { DeleteJob, UpdateJob, getMyJobs } from "../../../store/job/jobAction";
import bottomIcon1 from "../../../assets/img/bottomIcon-1.png";
import bottomIcon2 from "../../../assets/img/bottomIcon-2.png";
import bottomIcon3 from "../../../assets/img/bottomIcon-3.png";
import bottomIcon4 from "../../../assets/img/bottomIcon-4.png";
import { toast } from "react-toastify";
import axios from "axios";
const MyJobs = (props) => {
  const { setProfileField, setSelectedJob } = props;

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [currencyCodes, setCurrencyCodes] = useState([]);
  const dispatch = useDispatch();
  let { user } = useSelector((state) => state.auth);
  let { myJob } = useSelector((state) => state.job);

  const [filter, setFilter] = useState(
    user?.worker?.type == "worker" ? "saved" : "active"
  );

  const deactivateJob = (id) => {
    dispatch(UpdateJob(id, { expiry_date: new Date() }))
      .then((res) => {
        if (res.success) {
          toast.success("Job has been Deactivated");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const getActiveJobs = () => {
    dispatch(
      getMyJobs({ filter: "my", status: "active", wp_type: true, limit, skip })
    );
  };

  const getSavedJobs = () => {
    dispatch(getMyJobs({ filter: "saved", wp_type: true, limit, skip }));
  };

  const getAppliedJobs = () => {
    dispatch(getMyJobs({ filter: "applied", wp_type: true, limit, skip }));
  };

  const getDeactiveJobs = () => {
    dispatch(
      getMyJobs({
        filter: "my",
        status: "deactive",
        wp_type: true,
        limit,
        skip,
      })
    );
  };
  const currencySymbol = async () => {
    try {
      const res = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/USD`
      );
      if (res?.status == 200) {
        setCurrencyCodes(res?.data?.rates);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activateJob = (id) => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    let formattedDate = currentDate.toISOString();
    dispatch(UpdateJob(id, { expiry_date: formattedDate }))
      .then((res) => {
        if (res.success) {
          toast.success("Job has been activated");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const navigate = useNavigate();

  const deleteJob = (id) => {
    dispatch(DeleteJob(id))
      .then((res) => {
        if (res?.success) toast.success(res?.message);
      })
      .catch((err) => console.log(err?.response?.data?.message));
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const currentHeight =
        document.documentElement.scrollTop + window.innerHeight;
      if (currentHeight + 1 >= scrollHeight) {
        setSkip(skip + 10);
      }
    };

    window.addEventListener("scroll", handleScroll);

    if (filter == "saved") getSavedJobs();
    if (filter == "applied") getAppliedJobs();
    if (filter == "active") getActiveJobs();
    if (filter == "deactive") getDeactiveJobs();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [skip]);

  useEffect(() => {
    currencySymbol();
  }, []);

  const time = (date) => {
    const now = new Date();
    const createdAt = new Date(date);

    const timeDifference = now.getTime() - createdAt.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let timeAgo;

    if (daysDifference > 0) {
      timeAgo = daysDifference === 1 ? "1 day " : daysDifference + " days ";
    } else if (hoursDifference > 0) {
      timeAgo = hoursDifference === 1 ? "1 hr " : hoursDifference + " hrs ";
    } else if (minutesDifference > 0) {
      timeAgo =
        minutesDifference === 1 ? "1 min " : minutesDifference + " mins ";
    } else {
      timeAgo = "Just now";
    }
    return timeAgo;
  };

  return (
    <div className="profile-content-outer">
      <div className="workerpool-default-form-page px-2">
        <div className="back-btn" onClick={() => setProfileField("profile")}>
          <img src={backArrow} alt="Icon" />
        </div>
        <div className="head progress-box d-flex flex-wrap gap-2 justify-content-between w-100 pe-0">
          <div className="d-flex">
            {user?.worker?.type == "worker" ? (
              <>
                <div
                  className=""
                  onClick={() => {
                    setFilter("saved");
                    setSkip(0);
                    getSavedJobs();
                  }}
                >
                  <p
                    style={{ cursor: "pointer" }}
                    className={`${filter == "saved" ? "font-bold" : ""}`}
                  >
                    Saved Jobs
                  </p>
                </div>

                <div className="">
                  <p
                    style={{ cursor: "pointer" }}
                    className={`${
                      filter == "applied" ? "font-bold mx-3" : "mx-3"
                    }`}
                    onClick={() => {
                      setFilter("applied");
                      setSkip(0);
                      getAppliedJobs();
                    }}
                  >
                    Applied Jobs
                  </p>
                </div>
              </>
            ) : (
              <>
                <div
                  className=""
                  onClick={() => {
                    setFilter("active");
                    setSkip(0);
                    getActiveJobs();
                  }}
                >
                  <p
                    style={{ cursor: "pointer", fontSize: "16px" }}
                    className={`${
                      filter == "active" ? "font-bold" : "text-secondary"
                    }`}
                  >
                    Active Jobs
                  </p>
                </div>

                <div className="">
                  <p
                    style={{ cursor: "pointer", fontSize: "16px" }}
                    className={`${
                      filter == "deactive"
                        ? "font-bold mx-3"
                        : "mx-3 text-secondary"
                    }`}
                    onClick={() => {
                      setFilter("deactive");
                      setSkip(0);
                      getDeactiveJobs();
                    }}
                  >
                    Deactivated Jobs
                  </p>
                </div>
              </>
            )}
          </div>
          {user?.worker?.type !== "worker" && (
            <div className="">
              <button
                className="post_job p-3"
                onClick={() => navigate("/add-job-details")}
              >
                Post Job
              </button>
            </div>
          )}
        </div>
        <div className="col-12">
          <div className="row">
            {myJob?.length == 0 && (
              <div className="d-flex justify-content-center align-items-center col-12">
                <h2>No Jobs Found</h2>{" "}
              </div>
            )}

            {myJob?.map((ele) => {
              return (
                <>
                  <div
                    className="col-12"
                    keys={ele?.id}
                  >
                    <div className="p-lg-4 p-3" style={{ background: "#F9F9F9" }}>
                      <div className="col-outer flex-column">
                        <div className="d-flex flex-wrap gap-2 justify-content-between main_con mb-3">
                          <div
                            className="left"
                            onClick={() => navigate(`/job/${ele?.id}`)}
                            style={{ cursor: "pointer" }}
                          >
                            {ele?.title?.length > 30 ? (
                              <h3>{ele?.title?.slice(0, 30)}...</h3>
                            ) : (
                              <h3>{ele?.title}</h3>
                            )}
                            {ele?.company_name?.length > 30 ? (
                              <span className="detail">
                                {ele?.company_name?.slice(0, 30)}...
                              </span>
                            ) : (
                              <span className="detail">{ele?.company_name}</span>
                            )}
                          </div>
                          {user?.worker?.type != "worker" && (
                            <div className="right ">
                              {filter == "active" ? (
                                <>
                                  <div className="d-flex flex-wrap gap-md-3 gap-2">
                                    <button
                                      className="deactivate_btn py-2 px-lg-4 px-md-3 px-2 text-nowrap w-auto"
                                      onClick={() => deactivateJob(ele?.id)}
                                    >
                                      Deactivate
                                    </button>
                                    <button
                                      className="view_applicant_btn border-0 px-lg-4 px-md-3 px-2 text-nowrap w-auto"
                                      onClick={() => {
                                        setSelectedJob(ele?.id);
                                        setProfileField("view-applicant");
                                      }}
                                    >
                                      View Applicants
                                    </button>
                                  </div>
                                  
                                </>
                              ) : (
                                <>
                                <div className="d-flex flex-wrap gap-md-3 gap-2">
                                  <button
                                    className="del_btn py-2 px-lg-4 px-md-3 px-2 text-nowrap w-auto"
                                    onClick={() => deleteJob(ele?.id)}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className="publish_btn py-2 px-lg-4 px-md-3 px-2 text-nowrap w-auto"
                                    onClick={() => activateJob(ele?.id)}
                                  >
                                    Publish Again
                                  </button>
                                </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
                          <div className="bottom-text d-flex flex-wrap gap-2">
                            {ele?.job_location && (
                              <span>
                                <img src={bottomIcon1} alt="Icon" />{" "}
                                {ele?.job_location}
                              </span>
                            )}
                            <span className="mx-2">
                              <img src={bottomIcon2} alt="Icon" />{" "}
                              {time(ele?.createdAt)}
                            </span>
                            <span className="mx-2">
                              <img src={bottomIcon3} alt="Icon" />{" "}
                              {ele?.min_experience} - {ele?.max_experience}{" "}
                              {ele?.max_experience > 1 ? "years" : "year"}
                            </span>
                            <span className="mx-2">
                              <img src={bottomIcon4} alt="Icon" />{" "}
                              {Math.round(
                                ele?.min_salary *
                                  currencyCodes[ele?.salary_type || "USD"]
                              ) >= 1000
                                ? Math.round(
                                    (ele?.min_salary *
                                      currencyCodes[ele?.salary_type || "USD"]) /
                                      1000
                                  ) + "K"
                                : Math.round(
                                    ele?.min_salary *
                                      currencyCodes[ele?.salary_type || "USD"]
                                  )}{" "}
                              -{" "}
                              {Math.round(
                                ele?.max_salary *
                                  currencyCodes[ele?.salary_type || "USD"]
                              ) >= 1000
                                ? Math.round(
                                    (ele?.max_salary *
                                      currencyCodes[ele?.salary_type || "USD"]) /
                                      1000
                                  ) + "K"
                                : Math.round(
                                    ele?.max_salary *
                                      currencyCodes[ele?.salary_type || "USD"]
                                  )}{" "}
                              {ele?.salary_type || "USD"}
                            </span>
                            {ele?.employment_type && (
                              <span className="red-clr">
                                {ele?.employment_type == "part-time"
                                  ? "Part Time"
                                    ? ele?.employment_type == "full-time" ||
                                      ele?.employment_type == "Full-time"
                                    : "Full Time"
                                  : ele?.employment_type}
                              </span>
                            )}
                          </div>
                          <p  className="m-0 text-nowrap">
                          {Math.floor(
                            (new Date(ele?.expiry_date) - new Date()) /
                              (1000 * 60 * 60 * 24)
                          )}{" "}
                          days remaining
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div> 
      </div>
    </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
};

export default MyJobs;
